import React from 'react';
import { Link } from 'react-router-dom'

import Success from '../../assets/info-thankyou-hundred.svg';

import useMobile from '../../hooks/useMobile';
import GlobalState from '../../hooks/globalState';

import Header from '../../templates/header';

import { Container, Main, Info, Links, Logo, ButtonInfo, ContainerMobile, ModalHeader, LogoMobile, ButtonMobile } from './styles';

const ObrigadoCemMais: React.FC = () => {
    const mobile = useMobile();

    const desktopVersion = (
        <>
            <Header relative />

            <Container>
                <Main>
                    <Info>
                        <h2>Solicitação realizada com sucesso</h2>
                        <p>Em breve um dos nossos executivos entrará em contato.</p>

                        <Links>
                            <a href="https://dwscorretora.com/">
                                <ButtonInfo role="simpleInverted">
                                    <span>Voltar para o site</span>
                                </ButtonInfo>
                            </a>

                            <a href="https://dwscorretora.com/">
                                <ButtonInfo role="simpleInverted">
                                    <span>Últimas notíciais</span>
                                </ButtonInfo>
                            </a>

                        </Links>
                    </Info>

                    <Logo src={Success}></Logo>
                </Main>

            </Container>
        </>
    )

    const mobileVersion = (
        <>
            <ContainerMobile>
                <ModalHeader>
                    <LogoMobile src="/img/dws-vector-white.svg" alt="logo" />

                    <h1>Solicitação realizada com sucesso! </h1>
                    <p>Em breve um dos nossos executivos entrará em contato!</p>

                    <a href="https://dwscorretora.com/">
                        <ButtonMobile role="simpleInverted">
                            <span>Voltar para o site</span>
                        </ButtonMobile>
                    </a>

                    <a href="https://dwscorretora.com/">
                        <ButtonMobile role="simpleInverted">
                            <span>Últimas notíciais</span>
                        </ButtonMobile>
                    </a>

                </ModalHeader>

            </ContainerMobile>
        </>
    );


    return mobile ? mobileVersion : desktopVersion;
}

export default ObrigadoCemMais;
