import React from 'react';

import styled, { css } from 'styled-components';

export type InputProps = React.HTMLProps<HTMLInputElement> & {
    title?: string;
    error?: boolean;
    theme?: any;
    haveIcon?: boolean;
    select?: boolean;
};

interface GroupProps extends React.HTMLProps<HTMLDivElement> {
    error?: string;
    theme?: any;
}

interface IconProps extends React.HTMLProps<HTMLParagraphElement> {
    cursor?: string;
    theme?: any;
    hover?: boolean;
    color?: string;
}

export const Input: any = styled.input<InputProps>`
    padding-left: 0;
    padding-right: ${(props: InputProps) => (props.haveIcon ? '22px' : '0')};
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 5px;
    outline: none;
    background-color: transparent !important;
    border: none;
    color: ${(props: any) => props.theme.colors.gray1};
    /* border-bottom: 1px solid
        ${(props: InputProps) => props.theme.colors.darkblue}; */

    & + span + span {
        content: ' ';
        margin-top: 4px;
        display: block;
        width: 100%;
        position: relative;
    }

    & + span + span::before {
        content: ' ';
        display: block;
        margin: auto;
        height: 1px;
        width: 100%;
        position: absolute;
        /* transform: translateY(-3px); */
        background-color: ${(props: InputProps) => props.theme.colors.darkblue};
        /* transition: all 0.4s ease; */
    }

    & + span::before {
        content: ${(props: InputProps) =>
            props.title ? `"${props.title}"` : '""'};
        user-select: none;
        position: absolute;
        transform: translateY(-25px);
        font-size: 16px;
        transition: all 0.2s ease;
        color: ${(props: any) => props.theme.colors.gray1};
        transition: all 0.3s ease;
        pointer-events: none;
    }

    & + span + span::after {
        content: ' ';
        display: block;
        margin: auto;
        height: 2px;
        width: 0;
        transform: translateY(-1px);
        background-color: ${(props: InputProps) => props.theme.colors.yellow};
        transition: all 0.4s ease;
    }

    &:hover {
        /* border-bottom-width: 2px; */
        /* padding-bottom: 4px; */
        & + span + span::before {
            height: 2px;
            transform: translateY(-1px);
        }
    }

    @media (max-width: ${(props: InputProps) =>
            props.theme.breakpoints.mobile}) {
        &:hover {
            border-bottom-width: 1px !important;
            padding-bottom: 5px !important;
        }
    }

    & + span {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    & + span + span {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    /* Placeholder */
    & + span::after {
        content: '${(props: InputProps) => props.placeholder}';
        position: absolute;
        transform: translateY(-26px);
        display: none;
        color: #a8a8a8;
        pointer-events: none;
        transition: all 0.3s ease;
        z-index: 2;
    }

    &::placeholder {
        color: transparent;
    }

    &:focus {
        & + span::before {
            color: ${(props: InputProps) => props.theme.colors.yellow};
        }

        & + span::after {
            display: block;
        }

        & + span + span::after {
            width: 100%;
        }
    }

    &:focus,
    &:not([value='']) {
        & + span::before {
            font-size: 13px;
            transform: translateY(-50px);
        }
    }

    &:not([value='']) {
        & + span::after {
            display: none;
        }
    }

    ${(props: InputProps) =>
        props.error &&
        css`
            border-bottom-width: 2px;
            padding-bottom: 4px;
            border-bottom-color: tomato;
            & + span {
                &::before {
                    color: tomato !important;
                }
            }

            & + span + span::after {
                display: none;
            }

            & + span + span::before {
                height: 2px;
                background-color: tomato;
                transform: translateY(-1px);
            }
        `}
`;

export const Group = styled.div<GroupProps>`
    --verticalInput: ${(props: GroupProps) =>
        props.theme.spaces.vertical.input};
    margin-bottom: 35px;
    margin-top: var(--verticalInput);

    width: ${(props: GroupProps) => props.theme.sizes.desktopInput};

    display: block;
    position: relative;

    @media (max-width: ${(props: GroupProps) =>
            props.theme.breakpoints.mobile}) {
        width: ${(props: GroupProps) => props.theme.sizes.mobileInput};
    }

    ${(props: GroupProps) =>
        props.error &&
        css`
            margin-bottom: calc(var(--verticalInput) + 16px);
            &::after {
                content: '${props.error}';
                color: tomato;
                font-size: 13px;
                position: absolute;
            }
        `}
`;

export const Icon = styled.i<IconProps>`
    color: ${(props: IconProps) =>
        props.color ? props.color : props.theme.colors.darkblue};
    display: block;
    position: absolute;
    right: 0;
    cursor: ${(props: IconProps) => props.cursor || 'initial'};
    transform: translateY(4px);
    user-select: none;
    transition: all 0.3s ease;
    ${(props: IconProps) =>
        props.hover &&
        css`
            &:hover {
                color: ${props.theme.colors.yellow};
            }
        `}
`;
