import React, { useContext, useEffect, useState } from 'react';
import ButtonGroup from '../../../templates/buttonGroup';

import Tooltip from '../../../templates/tooltip';
import transform from '../../../utils/monetizer.js';
import {
    PlanDiv,
    PlanCol,
    PlanSection,
    InnerModal,
    ModalContainer,
    ModalHeader,
    ModalRow,
    Logo,
    ChevronIcon,
    TogglerContainer,
    CollapseContainer,
    CollapsibleContainer,
    EndButton,
    EndButtonContainer,
    Observations,
} from './styles';
import { Plan, Stars } from '../plan';
import { MobilePrevButton } from '../../../templates/navigationControls';
import {
    ActiveContext,
    Collapse,
    Collapsible,
    Toggler,
} from '../../../templates/collapse';
import useDebouncing from '../../../hooks/useDebouncing';

const Chevron = () => {
    const [open] = useContext(ActiveContext);
    return <ChevronIcon active={open} className="fas fa-chevron-down" />;
};

const TogglerContainerWrapper = (
    props: React.PropsWithChildren<any> & {
        bodyRef: React.Ref<HTMLDivElement>;
    },
) => {
    const { children, bodyRef } = props;
    const [open] = useContext(ActiveContext);
    const debounce = useDebouncing({
        callback: () => {
            if (open) bodyRef?.current?.scrollIntoView(true);
        },
        timeout: 500,
    });

    useEffect(() => {
        debounce.reset();
    }, [open]);

    return <TogglerContainer ref={bodyRef}>{children}</TogglerContainer>;
};

const CollapseWrapper = (
    props: React.PropsWithChildren<any> & { title: string; index: string },
) => {
    const { children, title, index } = props;
    const bodyRef: React.Ref<HTMLDivElement> = React.createRef();
    return (
        <CollapseContainer ref={bodyRef}>
            <Toggler index={index} containerTrigger>
                <TogglerContainerWrapper bodyRef={bodyRef}>
                    <p>{title}</p>
                    <Chevron />
                </TogglerContainerWrapper>
            </Toggler>
            <Collapsible index={index}>
                <CollapsibleContainer>{children}</CollapsibleContainer>
            </Collapsible>
        </CollapseContainer>
    );
};

const MobilePlan = (props: Plan) => {
    const {
        id,
        name,
        company,
        accommodation,
        reimbursement,
        partaking,
        coverage,
        hospitals,
        tableCoverage,
        diff,
        lack,
        total,
        saude,
        dental,
        observations,
        ranking,
        iof,
        modality,
        onChoose,
    } = props;

    const [open, setOpen] = useState(false);
    const [endbutton, setEndbutton] = useState(false);

    const modalRef: any = React.createRef();

    const hospitalList = hospitals?.map((val: string) => (
        // eslint-disable-next-line react/jsx-one-expression-per-line
        <p key={val}>{val};</p>
    ));

    useEffect(() => {
        const modal = modalRef.current;
        if (!modal) return;
        if (open) modal.scrollTop = 0;
        setEndbutton(false);
    }, [open]);

    const onScroll = (e: any) => {
        const modal = e.target;
        setEndbutton(modal.scrollTop >= 50);
    };

    const chooseHandler = () => {
        onChoose?.(id, props);
    };

    return (
        <>
            <PlanDiv onClick={() => setOpen(true)}>
                <PlanCol>
                    <PlanSection>
                        <small>{company.name}</small>
                        <h5>{name}</h5>
                    </PlanSection>
                    <PlanSection>
                        <small>Abrangência</small>
                        <p>{coverage}</p>
                    </PlanSection>
                    <PlanSection>
                        <small>Acomodação</small>
                        <p>{accommodation}</p>
                    </PlanSection>
                </PlanCol>
                <PlanCol>
                    <PlanSection>
                        <small>&nbsp;</small>
                        <h5>&nbsp;</h5>
                    </PlanSection>
                    <PlanSection>
                        <small>Classificação</small>
                        <Stars ranking={ranking} />
                    </PlanSection>
                    <PlanSection>
                        <small>Mensalidade</small>
                        <p>
                            R$&nbsp;
                            {transform(total || 0)}
                        </p>
                    </PlanSection>
                    <PlanSection>
                        <ButtonGroup text="Saiba Mais" buttonrole="simple" />
                    </PlanSection>
                </PlanCol>
            </PlanDiv>
            {/* ---------- MODAL ---------- */}
            <InnerModal ref={modalRef} active={open} onScroll={onScroll}>
                <ModalHeader>
                    <Logo src="/img/dws-vector-white.svg" alt="logo" />
                    <MobilePrevButton prev={() => setOpen(false)} />
                    <ModalRow marginTop="20px">
                        <PlanCol>
                            <PlanSection>
                                <h3>
                                    {company.name}
                                    &nbsp;
                                    {name}
                                </h3>
                            </PlanSection>
                        </PlanCol>
                    </ModalRow>
                    <ModalRow marginTop="25px">
                        <PlanCol>
                            <PlanSection>
                                <small>Abrangência</small>
                                <p>{coverage}</p>
                            </PlanSection>
                        </PlanCol>
                        <PlanCol>
                            <PlanSection>
                                <small>Classificação</small>
                                <Stars ranking={ranking} />
                            </PlanSection>
                        </PlanCol>
                    </ModalRow>
                    <ModalRow>
                        <PlanCol>
                            <PlanSection>
                                <small>Acomodação</small>
                                <p>{accommodation}</p>
                            </PlanSection>
                        </PlanCol>
                        <PlanCol>
                            <PlanSection>
                                <small>Mensalidade</small>
                                <p>
                                    R$&nbsp;
                                    {transform(total || 0)}
                                </p>
                            </PlanSection>
                        </PlanCol>
                    </ModalRow>
                </ModalHeader>
                <ModalContainer>
                    <ModalRow marginTop="20px">
                        <PlanCol>
                            <h5>Características</h5>
                            <PlanSection>
                                <small>Saúde</small>
                                <p>
                                    R$&nbsp;
                                    {transform(saude || 0)}
                                </p>
                            </PlanSection>
                            <PlanSection>
                                <small>Odonto</small>
                                <p>
                                    R$&nbsp;
                                    {transform(dental || 0)}
                                </p>
                            </PlanSection>
                            <PlanSection>
                                <small>IOF</small>
                                <p>
                                    R$&nbsp;
                                    {transform(iof || 0)}
                                </p>
                            </PlanSection>
                            <PlanSection>
                                <small>Reembolso</small>
                                <p>
                                    R$&nbsp;
                                    {transform(reimbursement || 0)}
                                </p>
                            </PlanSection>
                            <PlanSection>
                                <small>Coparticipação</small>
                                <p>{partaking ? 'Sim' : 'Não'}</p>
                            </PlanSection>
                            <PlanSection>
                                <small>Modalidade</small>
                                <p>{modality}</p>
                            </PlanSection>
                            <PlanSection>
                                <small>Cobertura</small>
                                <p>{tableCoverage}</p>
                            </PlanSection>
                        </PlanCol>
                    </ModalRow>
                </ModalContainer>
                <ModalContainer noPadding>
                    <Collapse accordion>
                        <CollapseWrapper title="Carências" index="carencias">
                            <p>
                                {`${lack}`
                                    .split('\n')
                                    .map(
                                        (
                                            value: string,
                                            index: number,
                                            array: string[],
                                        ) => {
                                            const newLine =
                                                index < array.length ? (
                                                    <br />
                                                ) : null;
                                            return (
                                                <React.Fragment
                                                    key={index * array.length}
                                                >
                                                    {value}
                                                    {newLine}
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                            </p>
                        </CollapseWrapper>
                        <CollapseWrapper
                            title="Principais Hospitais"
                            index="hospitals"
                        >
                            {hospitalList}
                        </CollapseWrapper>
                        <CollapseWrapper
                            title="Quem pode ser dependente"
                            index="dependents"
                        >
                            <p>{company.dependents}</p>
                        </CollapseWrapper>
                        <CollapseWrapper title="Sobre o Plano" index="about">
                            <small>
                                <b>Diferenciais</b>
                            </small>
                            <p>{diff}</p>
                            <br />
                            <small>
                                <b>
                                    Sobre a&nbsp;
                                    {company.name}
                                </b>
                            </small>
                            <p>{company.description}</p>
                        </CollapseWrapper>
                    </Collapse>
                    <Observations>
                        <i className="fas fa-info-circle" />
                        <p>{observations}</p>
                    </Observations>
                </ModalContainer>
                <EndButtonContainer active={endbutton}>
                    <EndButton onClick={chooseHandler}>
                        Contratar Plano
                    </EndButton>
                </EndButtonContainer>
            </InnerModal>
        </>
    );
};

export default MobilePlan;
