import styled from 'styled-components';

export const Group = styled.div`
    margin-top: 0px;
`;

export const Table = styled.table`
    display: block;
    margin: auto;
    margin-top: 17.5px;
`;

export const TableCol = styled.td`
    padding: 5px 0px 5px 0px;
    margin: 0;
    color: rgb(104, 104, 104);

    p {
        margin: 0;
        margin-left: 26px;
        color: ${(props: any) => props.theme.colors.darkblue};
    }
`;

export const TableBody = styled.tbody`
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

export const Description = styled.p`
    color: ${(props: any) => props.theme.colors.gray1};
    text-align: left;
    font-size: 16px;
    font-weight: normal;
`;
