import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Header from '../../templates/header';

import { ageRanges, states, urls } from '../../utils/defaultValues.js';
import GlobalState from '../../hooks/globalState';
import api from '../../utils/api';
import executeGtm from '../../utils/gtm.js';
import infoIndicator from '../../assets/checkedinfoindicator.svg';

import {
    MainDiv,
    Row,
    Col,
    Title,
    SectionTitle,
    Description,
    IndicatorImage,
    HorizontalLine,
    PlanBlock,
    PlanRow,
    PlanCol,
    PlanGrid,
    PlanTitle,
    PlanValue,
    ObsPlanSection,
    PlanTotal,
    MobileHeader,
    Logo,
    InfoIcon,
} from './styles';
import useMobile from '../../hooks/useMobile';
import Tooltip from '../../templates/tooltip';
import transform from '../../utils/monetizer.js';
import { getCompanyInfo } from '../../utils/company';
import ButtonGroup from '../../templates/buttonGroup';
import Head from '../../templates/head';

const Info = (props: { text: string }) => {
    const { text } = props;
    return (
        <>
            <InfoIcon
                className="fas fa-question-circle text-muted"
                data-tip={text}
                data-for="thankyou-tooltip"
            />
            <InfoIcon
                mobile
                className="fas fa-question-circle text-muted"
                data-tip={text}
                data-for="thankyou-tooltip-mobile"
            />
        </>
    );
};

const ThanksPage = () => {
    const [, , removeCookie] = useCookies();
    const [client] = GlobalState.useGlobalState('client');
    const [quotation] = GlobalState.useGlobalState('quotation');
    const [plan] = GlobalState.useGlobalState('selectedPlan');
    const history = useHistory();

    const mobile = useMobile();

    const company = getCompanyInfo(client, quotation);

    useEffect(() => {
        if (!quotation.selected_table && !Object.values(client).length) {
            history.push(urls.monteSeuPlano);
        } else {
            executeGtm(history.location.pathname);
            removeCookie('clientPk');
            removeCookie('lastPage');
            api.post(`/thank-you/${quotation.id}`);
        }
    }, []);

    const otherControls = (
        <>
            <ButtonGroup
                text="Voltar para o site"
                buttonrole="simpleInverted"
                inline
                properties={{ className: 'mr-4' }}
                onClick={() => {
                    window.location.href = 'https://dwscorretora.com/';
                }}
            />
            <ButtonGroup
                text="Últimas notíciais"
                inline
                buttonrole="simpleInverted"
                onClick={() => {
                    window.location.href = 'https://dwscorretora.com/blog/';
                }}
            />
        </>
    );

    const desktopVer = (
        <>
            <Header relative />
            <MainDiv>
                <Row justify="space-between">
                    <Col grow={2}>
                        <Title>Resumo do seu pedido</Title>
                        <Description>
                            O detalhamento do seu pedido será enviado para o
                            <br />
                            e-mail:&nbsp;
                            <b>{client.email}</b>
                        </Description>
                    </Col>
                    <Col align="flex-end">
                        <IndicatorImage src={infoIndicator} alt="checked" />
                    </Col>
                </Row>
                <Row>
                    <PlanBlock>
                        <SectionTitle>
                            {plan?.company.name}
                            &nbsp;
                            {plan?.name}
                        </SectionTitle>
                        <HorizontalLine margin={20} />
                        <h4>Dados da sua empresa</h4>
                        <p>
                            Porte da sua empresa:&nbsp;
                            <b>{company.size()}</b>
                        </p>
                        <p>
                            Quantidade de benefeciários:&nbsp;
                            <b>
                                {company.benefitCount()}
                                &nbsp;beneficiários
                            </b>
                        </p>
                        <p>
                            Estado:&nbsp;
                            <b>{company.state()}</b>
                        </p>
                        <PlanGrid margin={{ top: 40 }}>
                            <b>Valor do plano</b>
                            <PlanTotal>
                                R$&nbsp;
                                {transform(plan?.total)}
                            </PlanTotal>
                        </PlanGrid>
                        <HorizontalLine margin={20} />
                        <PlanGrid>
                            <b>
                                Reembolso&nbsp;
                                <Info text="Valor de reembolso por consultas médicas." />
                            </b>
                            <b>Coparticipação</b>
                            {/* Reembolso */}
                            <p>
                                R$&nbsp;
                                {transform(plan?.reimbursement)}
                            </p>
                            {/* Coparticipação */}
                            <p>{plan?.partaking ? 'Sim' : 'Não'}</p>
                        </PlanGrid>
                        <HorizontalLine margin={20} />
                        <PlanGrid margin={{ bot: 50 }}>
                            <b>Abrangência</b>
                            <b>
                                Modalidade&nbsp;
                                <Info
                                    text="Compulsória: Inclusão de todo o grupo segurável.
                                    Opcional: Não há obrigatoriedade de inclusão de todo o grupo segurável."
                                />
                            </b>
                            <p>{plan?.coverage}</p>
                            <p>{plan?.modality}</p>
                        </PlanGrid>
                        <ObsPlanSection>
                            <i className="fas fa-info-circle text-muted" />
                            <small>{plan?.observations}</small>
                        </ObsPlanSection>
                    </PlanBlock>
                </Row>
                <Row className="mt-5 justify-content-end">{otherControls}</Row>
            </MainDiv>
            <Tooltip id="thankyou-tooltip" />
        </>
    );

    const mobileVer = (
        <>
            <MobileHeader>
                <Logo src="/img/dws-vector-white.svg" />
                <h4>
                    <b>Resumo do seu pedido</b>
                </h4>
                <p>
                    O detalhamento do seu pedido será enviado para o e-mail:
                    <br />
                    <b>{client.email}</b>
                </p>
            </MobileHeader>
            <MainDiv>
                <Row>
                    <Col>
                        <SectionTitle>
                            {plan?.company.name}
                            &nbsp;
                            {plan?.name}
                        </SectionTitle>
                        <HorizontalLine margin={20} />
                        <h5>
                            <b>Dados da sua empresa</b>
                        </h5>
                        <p>
                            Porte da sua empresa:&nbsp;
                            <b>{company.size()}</b>
                        </p>
                        <p>
                            Quantidade de benefeciários:&nbsp;
                            <b>
                                {company.benefitCount()}
                                &nbsp;beneficiários
                            </b>
                        </p>
                        <p>
                            Estado:&nbsp;
                            <b>{company.state()}</b>
                        </p>
                        <HorizontalLine margin={20} />
                        <Col>
                            <PlanCol>
                                <PlanTitle>Valor do plano</PlanTitle>
                            </PlanCol>
                            <PlanCol>
                                <h4 className="mb-0">
                                    <b>
                                        R$&nbsp;
                                        {transform(plan?.total)}
                                    </b>
                                </h4>
                            </PlanCol>
                        </Col>
                        <HorizontalLine margin={20} />
                        <PlanRow>
                            <PlanTitle>
                                Reembolso&nbsp;
                                <Info text="Valor de reembolso por consultas médicas." />
                            </PlanTitle>
                            <PlanValue>
                                R$&nbsp;
                                {transform(plan?.reimbursement)}
                            </PlanValue>
                        </PlanRow>
                        <PlanRow>
                            <PlanTitle>Coparticipação</PlanTitle>
                            <PlanValue>
                                {plan?.partaking ? 'Sim' : 'Não'}
                            </PlanValue>
                        </PlanRow>
                        <PlanRow>
                            <PlanTitle>Abrangência</PlanTitle>
                            <PlanValue>{plan?.coverage}</PlanValue>
                        </PlanRow>
                        <PlanRow>
                            <PlanTitle>
                                Modalidade&nbsp;
                                <Info
                                    text="Compulsória: Inclusão de todo o grupo segurável.
                        Opcional: Não há obrigatoriedade de inclusão de todo o grupo segurável."
                                />
                            </PlanTitle>
                            <PlanValue>{plan?.modality}</PlanValue>
                        </PlanRow>
                        <PlanRow>
                            <PlanTitle>Cobertura</PlanTitle>
                            <PlanValue>
                                <b>{plan?.tableCoverage}</b>
                            </PlanValue>
                        </PlanRow>
                        <ObsPlanSection>
                            <i className="fas fa-info-circle text-muted" />
                            <small>{plan?.observations}</small>
                        </ObsPlanSection>
                        <HorizontalLine margin={20} />
                        <PlanRow className="mt-4" noMobile>
                            {otherControls}
                        </PlanRow>
                        <Tooltip id="thankyou-tooltip-mobile" />
                    </Col>
                </Row>
            </MainDiv>
        </>
    );

    return (
        <>
            <Head pageName="Obrigado" closedwon />
            {mobile ? mobileVer : desktopVer}
        </>
    );
};

export default ThanksPage;
