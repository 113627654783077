/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChildrenUnhider } from '../../hooks/childrenUnhider';
import { ErrorProvider } from '../../hooks/useError';
import { NavigationControls, MobilePrevButton } from '../navigationControls';
import StepIndicator from '../stepIndicator';
import {
    MainDiv,
    MainForm,
    LeftCol,
    RightCol,
    Title,
    Description,
    IndicatorImage,
    IndicatorText,
    SecurityDiv,
} from './styles';

interface StepProps {
    name?: string;
    stepName: 'step1' | 'step2';
    description?: any;
    rightCol?: {
        image?: string;
        text?: string | JSX.Element;
        security?: boolean;
    };
    nextPage?: string;
    previousPage?: string;
    children?: React.ReactNode;
    form?: boolean;
    tinyMargin?: boolean;
    submit?: (data: any, next: () => void) => Promise<any>;
    pageId?: number;
}

const Step = (props: StepProps) => {
    const history = useHistory();
    const {
        form,
        tinyMargin,
        name,
        children,
        description,
        rightCol,
        pageId,
        stepName,
    } = props;
    const { nextPage, previousPage } = props;

    const navigation = {
        next() {
            if (nextPage) history.push(nextPage);
        },
        previous() {
            if (previousPage) history.push(previousPage);
        },
    };

    const formRef: any = React.createRef();

    const submitHandler = () => {
        const ps = new Promise(
            (resolve: (value: any) => void, reject: (reason: any) => void) => {
                const data: any = {};
                new FormData(formRef.current).forEach(
                    (value: FormDataEntryValue, key: string) => {
                        data[key] = value;
                    },
                );
                props
                    .submit?.(data, navigation.next)
                    .then(() => {
                        resolve(true);
                    })
                    .catch((e: string) => reject(e));
            },
        );
        return ps;
    };

    const StepDiv = (cprops: any) => {
        const divProps = {
            onSubmit: form ? submitHandler : undefined,
            className: `mainDiv${tinyMargin ? ' tinyMargin' : ''}`,
            children: cprops.children,
        };
        return form ? (
            <MainForm ref={formRef} {...divProps} />
        ) : (
            <MainDiv {...divProps} />
        );
    };

    return (
        <StepDiv>
            <LeftCol>
                <MobilePrevButton
                    prev={previousPage ? navigation.previous : undefined}
                />
                {pageId !== undefined && (
                    <>
                        <StepIndicator pageId={pageId} quantity={3} />
                        <div className="d-none py-4 d-sm-block" />
                    </>
                )}
                {[
                    name ? <Title key={0}>{name}</Title> : null,
                    description ? (
                        <Description key={1}>{description}</Description>
                    ) : null,
                ]}
                <ErrorProvider step={stepName}>
                    <ChildrenUnhider>
                        {children}
                        <NavigationControls
                            index="auto"
                            next={!!nextPage}
                            prev={
                                previousPage ? navigation.previous : undefined
                            }
                            submit={submitHandler}
                        />
                    </ChildrenUnhider>
                </ErrorProvider>
                <div />
            </LeftCol>
            {rightCol ? (
                <RightCol>
                    <IndicatorImage
                        src={rightCol.image || ''}
                        alt="backImage"
                    />
                    <IndicatorText>{rightCol.text}</IndicatorText>
                    {rightCol.security && (
                        <SecurityDiv>
                            <img src="./img/shield.svg" alt="security" />
                            <small>
                                Não se preocupe, manteremos
                                <br />
                                todas as informações em&nbsp;
                                <b>sigilo.</b>
                            </small>
                        </SecurityDiv>
                    )}
                </RightCol>
            ) : null}
        </StepDiv>
    );
};

export default Step;
