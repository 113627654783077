import { ageRanges } from './defaultValues.js';

function verifierDigit(digits: any) {
    let soma = 0;
    const tamanho: number = digits.length;
    let pos: number = tamanho - 7;
    for (let x = 0; x < tamanho; x++) {
        soma += digits[x] * pos--;
        if (pos < 2) pos = 9;
    }
    return soma % 11 < 2 ? 0 : 11 - (soma % 11);
}

function allEqual(str: string) {
    return Array.from(str).every((value, index, array) => {
        return value === array[0];
    });
}

function validateCnpj(cnpj: string) {
    if (!cnpj || cnpj.length !== 14 || allEqual(cnpj)) return false;

    const first = verifierDigit(cnpj.substr(0, cnpj.length - 2));

    if (first !== parseInt(cnpj[cnpj.length - 2], 10)) return false;

    const second = verifierDigit(cnpj.substr(0, cnpj.length - 1));

    if (second !== parseInt(cnpj[cnpj.length - 1], 10)) return false;

    return true;
}

interface StepValidation {
    fields: any;
    validate: (
        errors: Map<string, string>,
        validationFields: any,
        data: any,
    ) => void;
}

interface ValidationSteps {
    step1: StepValidation;
    step2: StepValidation;
}

export const validations: ValidationSteps = {
    step1: {
        validate: (
            errors: Map<string, string>,
            validationFields: any,
            data: any,
        ) => {
            Object.entries(data).forEach((val: Array<any>) => {
                const [key, value] = val;
                const inputValidation: any = validationFields[key]?.(value);
                if (inputValidation?.result === false) {
                    errors.set(key, inputValidation.message);
                } else if (!value) {
                    errors.set(key, `Preencha o campo ${key}`);
                }
            });
        },
        fields: {
            name: (val: string) => ({
                result: Boolean(val),
                message: 'Por favor especifique o seu nome.',
            }),
            email: (val: string) => {
                const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                return { result: re.test(val), message: 'E-mail inválido.' };
            },
            phone: (val: string) => {
                const re = /^\([0-9]{2}\)\s([0-9]{4,5})-([0-9]{4})$/;
                return {
                    result: re.test(val),
                    message: 'Telefone para contato inválido.',
                };
            },
            cnpj: (val: string) => {
                const value = val || '';
                const cnpjString = value.replace(/[.-]|\//g, '');
                const valid = validateCnpj(cnpjString) || false;
                return { result: valid, message: 'CNPJ inválido.' };
            },
            role: (val: string) => ({
                result: Boolean(val),
                message: 'Por favor especifique o seu cargo.',
            }),
        },
    },
    step2: {
        validate: (
            errors: Map<string, string>,
            validationFields: any,
            data: any,
        ) => {
            let multiInputCheck = false;
            Object.entries(data).forEach((val: Array<any>) => {
                const [key, value] = val;
                let inputValidation: any;
                if ([...ageRanges, 'company_size'].includes(key)) {
                    if (multiInputCheck) return;
                    multiInputCheck = true;
                    inputValidation = validationFields.multiInput?.(data);
                } else {
                    inputValidation = validationFields[key]?.(value, data);
                }
                if (inputValidation?.result === false) {
                    errors.set(key, inputValidation.message);
                }
            });
        },
        fields: {
            multiInput: (data: any) => {
                const sizes: any = {
                    '02_29': [2, 29],
                    '30_99': [30, 99],
                    '100+': [100],
                };
                const size: Array<number> = sizes[data.company_size];
                if (!size) {
                    return {
                        result: false,
                        message:
                            'Por favor especifique o porte da sua empresa.',
                    };
                }
                if (size.length === 1) {
                    return { result: true, message: '' };
                }
                let age1 = 0;
                let total = 0;
                ageRanges.forEach((field: string) => {
                    const value: number = data[field];
                    if (value) {
                        if (field === 'age_range_1') age1 = value;
                        else total += value;
                    }
                });
                if (total === 0) {
                    return {
                        result: false,
                        message:
                            'É necessário pelo menos um beneficiário maior de 18 anos.',
                    };
                }
                total += age1;
                return {
                    result: !(total < size[0] || total > size[1]),
                    message: `A quantidade de beneficiários deve estar entre ${size[0]} e ${size[1]}`,
                };
            },
            state: (val: string) => ({
                result: Boolean(val),
                message: 'Por favor especifique o estado.',
            }),
            has_plan: (val: string, data: any) => {
                if (val === 'yes') {
                    const existingPlan = data.existing_plan;
                    if (!existingPlan) {
                        return {
                            result: false,
                            message: 'Por favor especifique o seu plano atual.',
                        };
                    }
                }
                return {
                    result: Boolean(val),
                    message: "Por favor marque a opção 'Tem plano médico'.",
                };
            },
        },
    },
};

export function validate(data: any, stepValidation: StepValidation) {
    const errors = new Map<string, string>();
    stepValidation.validate(errors, stepValidation.fields, data);

    return { valid: errors.size === 0, errors };
}
