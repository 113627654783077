import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { urls } from './utils/defaultValues.js';
import SeusDados from './pages/SeusDados';
import MonteSeuPlano from './pages/MonteSeuPlano';
import CompareEContrate from './pages/CompareEContrate';
import ThanksPage from './pages/Thanks';
import InvalidPage from './pages/Invalid';
import Simulation from './pages/Simulation';
import ObrigadoCemMais from './pages/ObrigadoCemMais';
import SolicitacaoCemMais from './pages/SolicitacaoCemMais';

interface RouterProps {
    page?: string; // ex: ["/step1" | "/step2"]
}

const Router = (props: RouterProps) => {
    const { page } = props;

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Switch>
                {/* {redirect} */}
                <Redirect from="/" to={urls.monteSeuPlano} exact />
                <Route path={urls.monteSeuPlano} component={MonteSeuPlano} />
                <Route path={urls.seusDados} component={SeusDados} />
                <Route
                    path={urls.compareEContrate}
                    component={CompareEContrate}
                />
                <Route path={urls.thanks} component={ThanksPage} />
                <Route path={urls.simulation} component={Simulation} />
                <Route path={urls.hundredSolicitation} exact component={SolicitacaoCemMais} />
                <Route path={urls.hundredThankYou} component={ObrigadoCemMais} />
                <Route path={urls.invalid} component={InvalidPage} />
                <Route
                    path={urls.invalidToken}
                    component={() => <InvalidPage type="invalidToken" />}
                />
                <Redirect from="*" to={urls.invalid} />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
