import React, { useState, useContext, useEffect, useMemo } from 'react';

export type Context = {
    lastchildren: [
        number | string,
        React.Dispatch<React.SetStateAction<number | string>>,
    ];
    childrenmap: [
        Map<number | string, [boolean, number | string]>,
        React.Dispatch<
            React.SetStateAction<
                Map<number | string, [boolean, number | string]>
            >
        >,
    ];
};

export type FormChildren = {
    hidden?: boolean;
    value?: any;
    index?: number | string | 'auto'; // "auto" for auto assign index
    customIncrement?: (
        props: FormChildren,
        unhider: {
            context: Context;
            hidden: () => boolean;
            increment: () => void;
            setVisibility: (index: number, visible?: boolean) => void;
            isLast: () => boolean;
        },
    ) => void;
};

const Context = React.createContext({} as Context);

const ChildrenUnhider = (props: { children: React.ReactNode }) => {
    const [lastChildren, setLastChildren] = useState<number | string>(-1);
    const [childrenMap, setChildrenMap] = useState(
        new Map<number | string, [boolean, number | string]>(),
    );

    const children =
        useMemo(
            () =>
                React.Children.map(
                    props.children,
                    (child: any, index: number) => {
                        if (child?.props?.index === undefined) return child;
                        const newProps = { ...child.props };
                        if (newProps.index === 'auto') newProps.index = index;

                        return React.cloneElement(
                            child,
                            newProps,
                            newProps.children,
                        );
                    },
                ),
            [],
        ) || [];

    useEffect(() => {
        const map = new Map(childrenMap);
        let lastId = -1;
        Array.from(children)
            .reverse()
            .forEach((child: any) => {
                if (!child) return;
                const isfirst = child.props === children[0].props;
                const { index } = child.props;
                map.set(index, [isfirst, lastId]);
                lastId = index;
            });
        setLastChildren(lastId);
        setChildrenMap(map);
    }, []);

    return (
        <Context.Provider
            value={{
                lastchildren: [lastChildren, setLastChildren],
                childrenmap: [childrenMap, setChildrenMap],
            }}
        >
            {children}
        </Context.Provider>
    );
};

function useUnhider(props: FormChildren) {
    const context = useContext(Context);

    const index =
        props.index !== 'auto' && props.index !== undefined ? props.index : -1;

    useEffect(() => {
        if (index === undefined) return;

        if (props.value) increment();
    });

    if (!context || !Object.keys(context).length) {
        return {
            context,
            hidden: () => false,
            increment: () => {},
            setVisibility: () => {},
            isLast: () => false,
        };
    }

    const {
        lastchildren: [lastChildren, setLastChildren],
        childrenmap: [childrenMap, setChildrenMap],
    } = context;

    const hidden = () =>
        !!(index !== -1 && lastChildren !== undefined
            ? !childrenMap.get(index)?.[0]
            : props.hidden);

    const isLast = () => lastChildren === index;

    const unhider = {
        context,
        hidden,
        increment,
        setVisibility,
        isLast,
    };

    function setVisibility(index: number, visible = false) {
        const map = new Map(childrenMap);
        const targetChildren = map.get(index);
        if (targetChildren) {
            targetChildren[0] = visible;
            map.set(index, targetChildren);
            setChildrenMap(map);
        }
    }

    function increment(
        ignoreCustom = false,
        target: number | string | undefined = undefined,
    ) {
        if (!ignoreCustom && props.customIncrement) {
            props.customIncrement(props, unhider);
        } else if (isLast()) {
            const map = new Map(childrenMap);
            const newTarget: number | string =
                target !== undefined ? target : map.get(index)?.[1] || -1;
            const targetValues = map.get(newTarget) || [false, -1];
            targetValues[0] = true;
            map.set(newTarget, targetValues);
            setLastChildren(newTarget);
            setChildrenMap(map);
        }
    }

    return unhider;
}

export default useUnhider;
export { ChildrenUnhider };
