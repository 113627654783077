import { useEffect, useState } from 'react';

export type Scroll = {
    scrollY: number;
    inTop: boolean;
    inBottom: boolean;
    distanceToBottom: number;
};

const useScroll = (callback: (scroll: Scroll) => void) => {
    const [scroll, setScroll] = useState<Scroll>({} as Scroll);

    const scrollListener = () => {
        const scrollObject: Scroll = {
            scrollY: window.scrollY,
            inBottom:
                window.innerHeight + window.scrollY >=
                document.body.scrollHeight,
            inTop: window.scrollY === 0,
            distanceToBottom:
                document.body.scrollHeight -
                (window.innerHeight + window.scrollY),
        };
        setScroll(scrollObject);
    };

    useEffect(() => {
        callback(scroll);
    }, [scroll]);

    useEffect(() => {
        scrollListener();
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, []);
};

export default useScroll;
