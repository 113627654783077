import { createGlobalStyle, css } from 'styled-components';
import '../fonts/fonts.scss';

const GlobalStyle = createGlobalStyle`
    html, body {
        width: 100%;
        height: 100%;
        background-color: #fff;
        font-weight: 300;
        scroll-behavior: smooth;
        font-family: ${props => props?.theme?.font?.family}, sans-serif;
    }

    .ReactCollapse--collapse {
        transition: height 500ms !important;
    }

    /* Adiciona selectors para cada elemento especificado na seção 'font' no tema escolhido */
    ${props =>
        props.theme
            ? Object.values(props.theme.font)
                  .filter(obj => typeof obj !== 'string')
                  .map(obj => Object.keys(obj))
                  .reduce((previous, next) => [
                      ...new Set(previous.concat(next)),
                  ])
                  .map(
                      element => css`
                          ${element} {
                              font-size: ${props.theme.font.size?.[element]};
                              font-weight: ${props.theme.font.weight?.[
                                  element
                              ]};
                              line-height: ${props.theme.font.lineHeight?.[
                                  element
                              ]};
                          }
                      `,
                  )
            : null}

    html {
        overflow-x: hidden !important;
    }

    img {
        -webkit-user-drag: none;
        user-select: none;
    }

    .dwsblue {
        color: #4463fb;
    }


    @keyframes inputAnim {
        from {
            transform: translateY(60px);
            filter: opacity(0);
        }
        to {
            transform: translateY(0px);
            filter: opacity(1);
        }
    }

    div.animatedInput {
        animation: 0.6s linear 0s inputAnim;
    }

    .dwsblue {
        color: #4463fb;
    }

    .sv__survey {
        right: 0px !important;
        margin-bottom: 300px;
    }

    .sv__btn-maximize-plus {
        display: none !important;
    }

    .sv__survey.sv__minimized {
        right: -62px !important;
        background-color: #FFC000 !important;
        color: #19324C !important;
        font-family: "IBMPlex";
        font-weight: 500;
        text-align: center !important;
        padding: 10px 50px 10px 50px !important;
        box-shadow: none !important;
        transform: rotate(-90deg);
    }

    @media only screen and (max-width: 567px) {
        .sv__survey {
            right: initial !important;
        }
        .sv__survey.sv__minimized {
            display: none;
            right: initial !important;
            margin-bottom: initial;
            transform: initial;
        }
    }

`;

export default GlobalStyle;
