import React from 'react';
import StyledTooltip from './styled';

const Tooltip = (props: { id: any; multiline?: boolean }) => {
    const { multiline, id } = props;
    return (
        <StyledTooltip
            id={id}
            effect="solid"
            type="light"
            multiline={multiline}
        />
    );
};

export default Tooltip;
