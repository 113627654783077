import React from 'react';
import styled from 'styled-components';

type Group = React.HTMLProps<HTMLDivElement>;

interface Input extends React.HTMLProps<HTMLInputElement> {
    theme?: any;
    initial?: boolean;
}
interface Button extends React.HTMLProps<HTMLButtonElement> {
    theme?: any;
}

export const Group = styled.div<Group>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 1px solid #929292;
    border-radius: 5px;
    width: 92px;
    height: 36px;
`;

export const Input = styled.input<Input>`
    width: 32px;
    font-size: ${(props: Input) => props.theme.font.size.p};
    text-align: center;
    border: 0;
    background: 0;
    height: 100%;
    outline: none !important;
    color: ${(props: Input) =>
        props.theme.colors[props.initial ? 'gray2' : 'darkblue']};
`;

export const Button = styled.button<Button>`
    height: 100%;
    width: 30px;
    border: 0;
    background: 0;
    outline: none !important;
    color: ${(props: Button) => props.theme.colors.darkblue};
    transition: all 0.2s ease;

    &:disabled,
    &:hover {
        color: ${(props: Button) => props.theme.colors.gray2};
    }
`;
