import React, { useContext, useEffect, useState } from 'react';
import { validations, validate } from '../utils/validation';
import GlobalState from './globalState';

interface ErrorProvider {
    step?: 'step1' | 'step2';
}

type Context = {
    errorMap: Map<string, string>;
    setErrorMap: React.Dispatch<React.SetStateAction<Map<string, string>>>;
    stepName: 'step1' | 'step2';
};

const Context = React.createContext({} as Context);

export const ErrorProvider = (
    props: React.PropsWithChildren<ErrorProvider>,
) => {
    const { children, step } = props;
    const [errorMap, setErrorMap] = useState(new Map<string, string>());
    return !step ? (
        <>{children}</>
    ) : (
        <Context.Provider value={{ errorMap, setErrorMap, stepName: step }}>
            {children}
        </Context.Provider>
    );
};

export function useError(
    identifier: string,
    value: any,
    deps?: Array<any>,
    global?: boolean,
) {
    const [globalErrors] = GlobalState.useGlobalState('errors');
    const { errorMap, setErrorMap, stepName } = useContext(Context);
    const [initialized, setInitialized] = useState(false);
    const [switchState, setSwitch] = useState(false);

    useEffect(() => {
        if (!initialized || global || !stepName) return;
        const data: any = {};
        data[identifier] = value;
        const validatedData = validate(data, validations[step]);
        const { valid, errors } = validatedData;
        const newmap = new Map(errorMap);
        let changed = false;
        if (!valid) {
            const error = errors.get(identifier);
            newmap.set(identifier, error || '');
            changed = true;
        } else {
            changed = newmap.delete(identifier);
        }
        if (changed) setErrorMap(newmap);
    }, deps || [switchState]);

    useEffect(() => {
        if (!global) setInitialized(true);
    }, []);

    if (!errorMap) return { getError: () => undefined };
    const step: 'step1' | 'step2' = stepName;
    const getError = () => (global ? globalErrors : errorMap).get(identifier);

    return {
        getError,
        setSwitch,
    };
}
