export function offsetTop(element: any): number {
    return (
        element.offsetTop +
        (element.offsetParent ? offsetTop(element.offsetParent) : 0)
    );
}

export default function scrollToMiddle(element: any) {
    if (element) {
        const top = offsetTop(element) - window.innerHeight / 2;
        window.scrollTo(0, top);
    }
}
