import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { AxiosResponse } from 'axios';
import Header from '../../templates/header';
import Step from '../../templates/step';
import Plan, { Plan as PlanType } from './plan';
import MobilePlan from './mobilePlan';

import GlobalState from '../../hooks/globalState';
import executeGtm from '../../utils/gtm.js';
import api from '../../utils/api';
import { urls, states, ageRanges } from '../../utils/defaultValues.js';
import { calculatePlanValue, getPlans } from '../../utils/plans';

import ProgressBar from '../../templates/progressBar';
import Tooltip from '../../templates/tooltip';
import ButtonGroup from '../../templates/buttonGroup';
import StepIndicator from '../../templates/stepIndicator';
import tailSpin from '../../assets/blue-tail-spin.svg';
import toTopImg from '../../assets/left-arrow.svg';

import {
    Container,
    IndicatorImage,
    Row,
    Col,
    LeftCol,
    RightCol,
    FilterContainer,
    PlanTable,
    PlanTableHead,
    Th,
    FilterButton,
    OrderBy,
    OrderByContainer,
    UserName,
    ToTopButton,
    ToTopButtonIcon,
    ShowMoreButtonDiv,
    PrevButton,
    Spinner,
    SpinnerContainer,
} from './styles';
import DynamicInputGroup from '../../templates/dynamicInputGroup';
import { Collapse } from '../../templates/collapse';
import { MobilePrevButton } from '../../templates/navigationControls';
import useMobile from '../../hooks/useMobile';
import useMapState from '../../hooks/useMapState';
import Filter from './filter';
import useScroll, { Scroll } from '../../hooks/useScroll';
import Head from '../../templates/head';

export const FilterContext: any = React.createContext([]);

const StepThree = () => {
    const [client] = GlobalState.useGlobalState('client');
    const [quotation] = GlobalState.useGlobalState('quotation');
    const [, , removeCookie] = useCookies();
    const [toTop, setToTop] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filterIsActive, setFilterActive] = useState(false);
    const [applied, setApplied] = useState(true);
    const [planList, setPlanList] = useState([]);
    const [cleared, setCleared] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [order, setOrder] = useState<string | null>(null);
    const [planCount, setPlanCount] = useState(0);
    const filterMap = useMapState<string, Set<string>>(
        new Map<string, Set<string>>(),
    );

    const mobile = useMobile();

    const history = useHistory();
    useEffect(() => {
        setApplied(true);
        if (!Object.values(client).length) {
            history.push(urls.seusDados);
        }
        executeGtm(history.location.pathname);
    }, []);

    function getPlansByFilters(customPage?: number) {
        setLoading(true);
        const applyPage = customPage !== undefined ? customPage : page;
        const query: Array<string> = [];
        Array.from(filterMap.map.entries()).forEach(
            (value: [string, Set<string>]) => {
                const [key, values] = value;
                if (key === 'hospitals_pk') {
                    query.push(`${key}=${[...values.values()].join(',')}`);
                } else {
                    values.forEach((filterName: string) => {
                        query.push(`${key}=${filterName}`);
                    });
                }
            },
        );
        if (order !== null && order !== '') query.push(`ordering=${order}`);
        const queryStr = `&${query.join('&')}`;
        const pageSize = 5;
        api.get(
            `/plans/table/${
                quotation.id
            }?page=${applyPage}&page_size=${pageSize}${
                query.length ? queryStr : ''
            }`,
        )
            .then((value: AxiosResponse<any>) => {
                const response = value.data;
                setHasMore(response.next !== null);
                setPlanCount(response.count);
                if (applyPage === 1) {
                    setPlanList(response.results);
                } else {
                    const newPlanList: any = [...planList, ...response.results];
                    setPlanList(newPlanList);
                }
                setLoading(false);
            })
            .catch((e: Error) => null);
    }

    useEffect(() => {
        setPage(1);
        if (applied || cleared) {
            if (cleared && !applied) {
                setApplied(true);
            } else {
                setCleared(false);
                getPlansByFilters(1);
            }
        }
    }, [applied]);

    useEffect(() => {
        if (order !== null) {
            setPage(1);
            getPlansByFilters(1);
        }
    }, [order]);

    useEffect(() => {
        if (page !== 1) {
            getPlansByFilters();
        }
    }, [page]);

    useScroll((scroll: Scroll) => {
        if (scroll.distanceToBottom <= 100 && !isLoading && hasMore) {
            setPage(page + 1);
        }
        setToTop(scroll.scrollY >= 200);
    });

    const onChoose = (id: number, props: PlanType) => {
        api.patch(`/quotation/${quotation.id}`, {
            selected_table: id,
            ...quotation,
        })
            .then(() => {
                GlobalState.setGlobalState('selectedPlan', props);
                history.push(urls.thanks);
            })
            .catch((e: Error) => null);
    };

    function prev() {
        return history.push(urls.seusDados);
    }

    const plans = useMemo(
        () =>
            getPlans(planList, quotation).map((plan: any) => {
                const planProps = { key: plan.id, ...plan, onChoose };
                return mobile ? (
                    <MobilePlan {...planProps} />
                ) : (
                    <Plan {...planProps} />
                );
            }),
        [mobile, planList],
    );

    const loadingSpinner = (
        <SpinnerContainer>
            <Spinner
                src={tailSpin}
                active={isLoading}
                alt="carregando planos..."
            />
        </SpinnerContainer>
    );

    const orderByElement = (
        <OrderBy
            onChange={(e: any) => setOrder(e.target.value)}
            value={order || ''}
        >
            <option value="">{mobile ? 'Ordenar por' : 'Selecionar'}</option>
            <option value="-total">Maior preço</option>
            <option value="total">Menor preço</option>
        </OrderBy>
    );

    const desktopVer = (
        <>
            <Row first>
                <LeftCol>
                    <h3>
                        Olá&nbsp;
                        <UserName>{client.name?.split(' ')?.[0]}</UserName>
                        , conheça os planos
                        <br />
                        que separamos para você.
                    </h3>
                </LeftCol>
            </Row>
            <Row justify="center" coverScreen height="30px" marginTop="30px">
                <FilterContainer>
                    <FilterButton
                        onClick={() => setFilterActive(!filterIsActive)}
                    >
                        Filtrar&nbsp;
                        <i className="fas fa-sliders-h" />
                    </FilterButton>
                    <OrderByContainer>
                        <h5>Ordenar por: </h5>
                        {orderByElement}
                        <i className="fa fa-chevron-down" />
                    </OrderByContainer>
                </FilterContainer>
            </Row>
            <Row justify="center">
                <PlanTable>
                    <PlanTableHead>
                        <Th first>
                            <p>
                                {`${planCount} `}
                                Planos
                            </p>
                        </Th>
                        <Th>
                            <p>Abrangência</p>
                        </Th>
                        <Th>
                            <p>Acomodação</p>
                        </Th>
                        <Th>
                            <p>Classificação</p>
                        </Th>
                        <Th>
                            <p>Mensalidade</p>
                        </Th>
                        <Th last />
                    </PlanTableHead>
                    <Collapse accordion>{plans}</Collapse>
                </PlanTable>
            </Row>
            {loadingSpinner}
        </>
    );

    const mobileVer = (
        <>
            <MobilePrevButton prev={prev} />
            <h4>
                Olá&nbsp;
                <UserName>{client.name?.split(' ')?.[0]}</UserName>
                ,&nbsp;conheça os planos que separamos para você.
            </h4>
            <Row marginTop="30px">&nbsp;</Row>
            <FilterContainer>
                <FilterButton onClick={() => setFilterActive(!filterIsActive)}>
                    Filtrar&nbsp;
                    <i className="fas fa-sliders-h" />
                </FilterButton>
                {orderByElement}
            </FilterContainer>

            <Col justify="center">{plans}</Col>
            {loadingSpinner}
        </>
    );

    return (
        <>
            <Head
                pageName="Compare os melhores planos para a sua empresa"
                simulation
            />
            <Header relative />
            <ProgressBar quantity={3} pageId={2} />
            <Container>
                <FilterContext.Provider
                    value={[
                        [filterIsActive, setFilterActive],
                        [applied, setApplied],
                        filterMap,
                        [cleared, setCleared],
                    ]}
                >
                    <Filter />
                </FilterContext.Provider>
                {mobile ? mobileVer : desktopVer}
            </Container>
            <ToTopButton
                active={toTop}
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
                data-tip="Voltar ao topo"
                data-for="compare-e-contrate-tooltip"
            >
                <ToTopButtonIcon src={toTopImg} />
            </ToTopButton>
            <Tooltip id="compare-e-contrate-tooltip" />
        </>
    );
};

export default StepThree;
