import ReactGA from 'react-ga';
import { gaId } from './defaultValues.js';

// let firstRequest = true;

if (gaId) {
    // ReactGA.initialize(gaId, {
    //     debug: false,
    // });
}

function executeGtm(pagePath) {
    // if (!gaId) return;
    // if (firstRequest) firstRequest = false;
    // else ReactGA.pageview(pagePath);
}

export default executeGtm;
