import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { AxiosResponse } from 'axios';

import Header from '../../templates/header';

import api from '../../utils/api';
import GlobalState from '../../hooks/globalState';
import { urls } from '../../utils/defaultValues.js';

import './styles.scss';
import { getReverseState } from '../../utils/company';

const Simulation = () => {
    const { token }: any = useParams();
    const history = useHistory();

    useEffect(() => {
        api.get(`/simulation/${token}`)
            .then((response: AxiosResponse) => {
                const { data } = response;
                const { client_data: client } = data;
                client.role = client.name_role;
                delete client.name_role;
                client.state = getReverseState(client.name_state);
                delete client.name_state;
                // quotation clear //
                delete data.date_quotation;
                delete data.client_data;
                delete data.client;
                GlobalState.setGlobalState('client', client);
                GlobalState.setGlobalState('quotation', data);
                history.push(urls.compareEContrate);
            })
            .catch(() => {
                history.push(urls.invalidToken);
            });
    }, []);

    return (
        <>
            <Header relative />
            <div className="simulation w-100 h-100">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <h1>Carregando sua cotação</h1>
            </div>
        </>
    );
};

export default Simulation;
