import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, InputProps } from '../dynamicInputGroup/styles';

interface MenuProps extends React.HTMLProps<HTMLUListElement> {
    theme?: any;
}

interface MenuItemProps extends React.HTMLProps<HTMLLIElement> {
    theme?: any;
    text?: string;
    active?: boolean;
}

export const Menu = styled.ul<MenuProps>`
    position: absolute;
    z-index: 3;
    width: ${(props: MenuProps) => props.theme.sizes.desktopInput};
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    margin: 0;
    padding: 5px 0px;
    border-radius: 5px;
    box-shadow: 0 1px 1px #26324668;
    scroll-behavior: smooth;
    list-style: none;
    background-color: ${props => props.theme.colors.gray4};

    & + div {
        content: '';
        background-color: #0000;
        top: 0;
        left: 0;
        position: fixed;
        display: none;
        width: 100vw;
        height: 100vh;
        transition: all 0.4s ease;
    }

    @media (max-width: ${(props: MenuProps) =>
            props.theme.breakpoints.mobile}) {
        @keyframes menuAnim {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        position: fixed;
        text-align: center;
        width: 100vw;
        max-height: 30vh;
        height: 30vh;
        left: 0;
        bottom: 0;
        box-shadow: none;

        animation: menuAnim 0.2s linear;
        & + div {
            z-index: 2;
            display: none;
            background-color: #0009;
        }
    }
`;

export const ChevronIcon = styled(Icon)<{ active?: boolean }>`
    height: 14px !important;
    &::before {
        height: 14px !important;
        display: block;
    }
    ${(props: any) =>
        props.active &&
        css`
            transform: rotateZ(180deg) translateY(-3px);
        `}
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        top: 5px;
    }
`;

export const MenuItem = styled.li<MenuItemProps>`
    padding: 2px 10px;

    ${(props: MenuItemProps) => {
        const selector = `background-color: ${props.theme.colors.gray3};`;
        return props.active
            ? selector
            : css`
                  &:hover {
                      ${selector}
                  }
              `;
    }}

    &:hover {
        background-color: ${(props: MenuItemProps) => props.theme.colors.gray3};
    }
    &::after {
        content: ${(props: MenuItemProps) =>
            props.text ? `'${props.text}'` : "''"};
    }

    @media (max-width: ${(props: MenuItemProps) =>
            props.theme.breakpoints.mobile}) {
        background-color: transparent !important;
        & + li::before {
            content: '';
            display: block;
            margin: auto;
            height: 1px;
            width: 80%;
            margin-bottom: 5px;
            background-color: ${(props: MenuItemProps) =>
                props.theme.colors.gray3};
        }
    }
`;

export const MenuControls = styled.div`
    position: fixed;
    display: none;
    justify-content: flex-end;
    align-items: center;
    background-color: ${(props: any) => props.theme.colors.gray4};
    height: 30px;
    bottom: 30vh;
    width: 100vw;
    left: 0;
    box-shadow: 0 0px 10px #26324638;
    z-index: 4;

    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        display: flex;
    }
`;

export const MenuButton = styled.button`
    display: block;
    margin-right: 10px;
    margin-left: 5px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    outline: none;
    border: none;
    box-shadow: 0 1px 1px #26324668;
`;

export const MobileSelect = styled.select<InputProps>`
    padding-left: 0;
    padding-right: ${(props: InputProps) => (props.haveIcon ? '22px' : '0')};
    width: 100%;
    display: block;
    font-size: 16px;
    padding-bottom: 5px;
    outline: none;
    background-color: transparent !important;
    border: none;
    color: #686868;

    &::-ms-expand {
        display: none;
    }
    -webkit-appearance: none;
    appearance: none;

    & + span::before {
        content: ' ';
        display: block;
        margin: auto;
        height: 1px;
        width: 100%;
        transform: translateY(0px);
        background-color: ${(props: InputProps) => props.theme.colors.darkblue};
        transition: all 0.4s ease;
    }

    & + span::after {
        content: ' ';
        display: block;
        margin: auto;
        height: 2px;
        width: 0;
        transform: translateY(-2px);
        background-color: ${(props: InputProps) => props.theme.colors.yellow};
        transition: all 0.4s ease;
    }

    &:hover {
        border-bottom-width: 2px;
        padding-bottom: 4px;
    }

    @media (max-width: ${(props: InputProps) =>
            props.theme.breakpoints.mobile}) {
        &:hover {
            border-bottom-width: 1px !important;
            padding-bottom: 5px !important;
        }
    }

    &::placeholder {
        color: transparent;
        transition: all 0.3s ease;
    }

    &:focus {
        &::placeholder {
            color: #a8a8a8;
        }

        & + span::after {
            width: 100%;
        }
    }

    ${(props: InputProps) =>
        props.error &&
        css`
            border-bottom-width: 2px;
            padding-bottom: 4px;
            border-bottom-color: tomato;
            & + span {
                &::before {
                    color: tomato !important;
                }
                &::after {
                    display: none;
                }
            }
        `}
`;
