import { HTMLProps } from 'react';
import styled, { css } from 'styled-components';

interface PlanCol extends HTMLProps<HTMLDivElement> {
    theme?: any;
    first?: boolean;
    last?: boolean;
}

interface Star {
    active?: boolean;
    percent?: number;
}

interface Icon {
    active?: boolean;
    theme?: any;
}

export const PlanBody = styled.div`
    @keyframes planAnim {
        from {
            filter: opacity(0);
        }
        to {
            filter: opacity(1);
        }
    }

    animation: 0.6s linear 0s planAnim;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-left: 3px solid ${(props: any) => props.theme.colors.yellow};
    margin-top: 12px;
    box-shadow: 0px 3px 10px rgba(38, 50, 70, 0.1);
    transition: box-shadow 0.3s ease;
    &:hover {
        box-shadow: 0px 0px 10px rgba(38, 50, 70, 0.2);
    }
`;

export const PlanRow = styled.div`
    cursor: pointer;
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    width: 100%;
`;

export const PlanCol = styled.div<PlanCol>`
    background-color: #fefeff;
    position: relative;
    height: 80px;
    display: flex;
    flex: 1;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    *:not(i) {
        text-align: left;
    }
    ${(props: PlanCol) =>
        props.first &&
        css`
            /* align-items: flex-start; */
            div {
                /* align-self: flex-start; */
                margin: 0;
                margin-left: 24px;
                padding: 0;
                /* margin-left: 24px; */
            }
        `}
    ${(props: PlanCol) =>
        props.last &&
        css`
            align-items: center;
            flex-basis: 100px;
            flex-grow: 0;
        `};
`;

export const PlanTitle = styled.p<{ company: string }>`
    position: relative;
    margin: 0;
    font-weight: 400;
    &::before {
        content: '${(props: any) => props.company}';
        font-size: 12px;
        font-weight: 300;
        position: absolute;
        top: -20px;
        left: 0;
    }
`;

export const Icon = styled.i<Icon>`
    color: ${(props: Icon) => props.theme.colors.yellow};
    font-size: 22px;
    transition: transform 0.3s ease;
    ${(props: Icon) =>
        props.active &&
        css`
            transform: rotateZ(-180deg);
        `};
`;

export const Star = styled.i<Star>`
    ${(props: Star) =>
        !props.active &&
        css`
            opacity: 0;
        `}
    color: ${(props: any) => props.theme.colors.yellow};
    font-size: 12px;
    margin-right: 2px;

    &:last-child {
        margin-right: 0;
    }
    position: relative;
`;

export const StarDiv = styled.div`
    display: flex;
`;

export const PlanView = styled.div`
    color: ${(props: any) => props.theme.colors.gray1};
    margin: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    padding: 0px 0px 20px 0px;
`;

export const PlanViewRow = styled.div<{ last?: boolean }>`
    position: relative;
    display: flex;
    flex: 1;
    ${(props: any) =>
        props.last &&
        css`
            margin-top: 45px;
        `}/* grid-column-gap: 24px; */

    /* grid-template-areas: 'info info leftcol leftcol leftcol'; */
    /* grid-template-columns: 0.73fr 1fr; */
`;

export const PlanViewGrid = styled.div<{ grid: string[] }>`
    /* margin-top: 100px; */
    position: relative;
    display: grid;
    flex: 1;
    grid-column-gap: 24px;
    grid-row-gap: 22px;
    margin-top: 12px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ${(props: { grid: string[] }) =>
        props.grid.map((value: string) => `'${value}'`).join(' ')};
`;

export const PlanViewCol = styled.div<{
    grow?: number;
    align?: string;
    justify?: string;
    padding?: {
        left?: string;
        right?: string;
        top?: string;
        bottom?: string;
    };
}>`
    position: relative;
    margin: 0;
    display: flex;
    flex: 1;
    flex-grow: ${(props: any) => props.grow || 1};
    align-items: center;

    ${(props: any) =>
        props.align &&
        css`
            align-items: ${props.align};
        `}
    ${(props: any) =>
        props.justify &&
        css`
            justify-content: ${props.justify};
        `}
    ${(props: any) =>
        props.padding &&
        css`
            ${Object.entries(props.padding)
                .map((value: any) => `padding-${value[0]}: ${value[1]};`)
                .join(' ')}
        `}
`;

export const HospitalDiv = styled.div`
    display: flex;
    padding-right: 24px;
    flex-wrap: wrap;
`;

export const Hospital = styled.small`
    padding: 9px 16px;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    word-break: keep-all;
    background-color: #e9e9e9;
    border-radius: 50px;
    margin-top: 10px;
    margin-right: 10px;
`;

export const PlanSection = styled.section<{ first?: boolean; name?: string }>`
    /* flex: 1; */
    /* flex-grow: 1; */
    padding: 0;
    p {
        margin-bottom: 0px;
    }
    ${(props: any) =>
        props.name &&
        css`
            grid-area: ${props.name};
        `}
    ${(props: any) =>
        props.first &&
        css`
            p {
                padding-left: 24px;
            }
        `} /* margin-bottom: 20px; */
`;

export const ButtonPlanSection = styled.section`
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 25px;
    margin-top: 35px;
    display: flex;
    position: relative;
    &::before {
        content: ' ';
        width: calc(100% - 48px);
        height: 1px;
        background-color: ${(props: any) => props.theme.colors.gray5}bb;
        position: absolute;
        top: 0;
        left: 24px;
    }
`;

export const ObsPlanSection = styled.div`
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    i {
        font-size: 30px;
        margin-right: 20px;
    }
`;

export const TextBlock = styled.div<{ active?: boolean }>`
    display: block;
    background-color: white;
    box-shadow: -5px 10px 10px #0001;
    position: absolute;
    left: 24px;
    z-index: 1;
    top: 90%;
    width: calc(50% - 24px);
    border-radius: 5px;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    ${(props: any) =>
        props.active &&
        css`
            opacity: 1;
        `}
    p {
        /* color: #; */
        margin: 0;
        font-size: 12px;
    }
`;
