import styled from 'styled-components';

interface logoProps extends React.HTMLProps<HTMLImageElement> {
    desktop?: boolean;
    theme?: any;
}

export const HeaderElement = styled.header`
    position: ${(props: any) => (props.relative ? 'relative' : 'fixed')};
    top: 0;
    display: flex;
    padding: 10px 30px 10px 30px;
    width: 104.1%;
    height: ${(props: any) => props.theme.sizes.header};
    background-color: ${(props: any) =>
        props.transparent ? 'transparent' : props.theme.colors.darklightblue};
    box-shadow: ${(props: any) =>
        props.transparent ? 'none' : '0 3px 10px #26324648'};
    z-index: 1;

    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        position: relative !important;
    }
`;

export const Logo = styled.img<logoProps>`
    padding: 0;
    margin: 0;
    margin-left: calc(8vw - 12px);
    display: ${(props: logoProps) => (props.desktop ? 'none' : 'block')};
    width: auto;
    height: ${(props: logoProps) => props.theme.sizes.minLogo};

    @media (min-width: ${(props: logoProps) =>
            props.theme.breakpoints.mobile}) {
        margin-left: calc(13vw - 12px);
        width: ${(props: logoProps) => props.theme.sizes.logo};
        height: auto;
        display: ${(props: logoProps) => (props.desktop ? 'block' : 'none')};
    }
`;
