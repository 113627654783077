import React from 'react';
import { Circle, CircleGroup } from './styles';

interface StepIndicatorProps {
    quantity: number;
    pageId?: number;
}

const getStepType = (
    index: number,
    length: number,
): 'first' | 'last' | undefined => {
    let type: 'first' | 'last' | undefined;
    switch (index) {
        case length - 1:
            type = 'last';
            break;
        case 0:
            type = 'first';
            break;
        default:
            type = undefined;
    }
    return type;
};

const StepCircle = (props: {
    type?: 'first' | 'last' | undefined;
    active?: boolean;
}) => {
    const { type, active } = props;
    return (
        <Circle type={type} active={active}>
            <div />
            <span />
        </Circle>
    );
};

const StepIndicator = (props: StepIndicatorProps) => {
    const { pageId, quantity } = props;
    const steps = Array(quantity)
        .fill(null)
        .map((_: any, index: number) => {
            const type = getStepType(index, quantity);
            return (
                <StepCircle
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    type={type}
                    active={index <= (pageId || 0)}
                />
            );
        });

    return <CircleGroup>{steps}</CircleGroup>;
};

export default StepIndicator;
