const version = 0;
const gaId = process.env.REACT_APP_GAID;

const states = [
    ['Acre', 'AC'],
    ['Alagoas', 'AL'],
    ['Amapá', 'AP'],
    ['Amazonas', 'AM'],
    ['Bahia', 'BA'],
    ['Ceará', 'CE'],
    ['Distrito Federal', 'DF'],
    ['Espírito Santo', 'ES'],
    ['Goiás', 'GO'],
    ['Maranhão', 'MA'],
    ['Minas Gerais', 'MG'],
    ['Mato Grosso do Sul', 'MS'],
    ['Mato Grosso', 'MT'],
    ['Pará', 'PA'],
    ['Paraíba', 'PB'],
    ['Pernambuco', 'PE'],
    ['Piauí', 'PI'],
    ['Paraná', 'PR'],
    ['Rio de Janeiro', 'RJ'],
    ['Rio Grande do Norte', 'RN'],
    ['Rondônia', 'RO'],
    ['Roraima', 'RR'],
    ['Rio Grande do Sul', 'RS'],
    ['Santa Catarina', 'SC'],
    ['Sergipe', 'SE'],
    ['São Paulo', 'SP'],
    ['Tocantins', 'TO'],
];

const benefits = [
    ['de 02 a 29 beneficiários', '02_29'],
    ['de 30 a 99 beneficiários', '30_99'],
    ['acima de 100 beneficiários', '100+'],
];

const multiInputValues = [
    ['00 a 18', 'age_range_1'],
    ['19 a 23', 'age_range_2'],
    ['24 a 28', 'age_range_3'],
    ['29 a 33', 'age_range_4'],
    ['34 a 38', 'age_range_5'],
    ['39 a 43', 'age_range_6'],
    ['44 a 48', 'age_range_7'],
    ['49 a 53', 'age_range_8'],
    ['54 a 58', 'age_range_9'],
    ['59+', 'age_range_10'],
];

const ageRanges = [
    'age_range_1',
    'age_range_2',
    'age_range_3',
    'age_range_4',
    'age_range_5',
    'age_range_6',
    'age_range_7',
    'age_range_8',
    'age_range_9',
    'age_range_10',
];

const urls = {
    seusDados: '/seus-dados',
    monteSeuPlano: '/monte-seu-plano',
    compareEContrate: '/compare-e-contrate',
    thanks: '/obrigado',
    invalid: '/pagina-inexistente',
    invalidToken: '/cotacao-invalida',
    simulation: '/simulacao/:token',
    hundredMore: '/exclusivo',
    hundredThankYou: '/exclusivo/obrigado',
    hundredSolicitation: '/solicitacao/exclusivo',
};

const existingPlanOptions = [
    'Allianz',
    'Amil',
    'Bradesco',
    'Caixa Seguros',
    'CAMED',
    'Care Plus',
    'Gama Saúde',
    'Golden Cross',
    'Lincx',
    'Marítima',
    'MediService',
    'NotreDame Intermédica',
    'Omint',
    'One Health',
    'Porto Seguro',
    'Sompo',
    'SulAmérica',
    'Unimed',
].map(v => [v, v]);

module.exports = {
    states,
    benefits,
    multiInputValues,
    urls,
    ageRanges,
    version,
    gaId,
    existingPlanOptions,
};
