import React, { useEffect } from 'react';
import useUnhider, { FormChildren } from '../../hooks/childrenUnhider';
import { Group, Button } from './styles';

interface ButtonGroup extends FormChildren {
    text?: any;
    submit?: boolean;
    focused?: boolean;
    hidden?: boolean;
    inline?: boolean;
    disabled?: boolean;
    removeAnimation?: boolean;
    onClick?: any;
    buttonrole?:
        | 'primary'
        | 'secondary'
        | 'action'
        | 'knowmore'
        | 'simple'
        | 'simpleInverted';
    properties?: any;
}

const ButtonGroup = React.forwardRef((props: ButtonGroup, ref: any) => {
    const buttonRef: any = React.createRef();
    const {
        submit,
        disabled,
        buttonrole,
        text,
        focused,
        removeAnimation,
        onClick,
        properties,
    } = props;

    const unhider = useUnhider(props);

    const clicker = (e: any) => {
        onClick?.(e, props);
    };

    useEffect(() => {
        if (focused) buttonRef.current.focus();
    });

    return (
        <Group
            ref={ref}
            className={!removeAnimation ? 'animatedInput' : ''}
            hidden={unhider.hidden()}
        >
            <Button
                onClick={clicker}
                type={submit && !unhider.hidden() ? 'submit' : 'button'}
                disabled={disabled}
                ref={buttonRef}
                role={buttonrole || 'primary'}
                {...(properties || {})}
            >
                {text}
            </Button>
        </Group>
    );
});

export default ButtonGroup;
