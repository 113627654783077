import React from 'react';
import styled from 'styled-components';

interface CircleProps extends React.HTMLProps<HTMLLIElement> {
    type?: 'first' | 'last' | undefined;
    active?: boolean;
    theme?: any;
}

export const Circle = styled.li<CircleProps>`
    --circleSize: ${(props: any) => props.theme.sizes.stepCircle};
    --stepColor: ${(props: any) =>
        props.theme.colors[props.active ? 'yellow' : 'gray5']};
    width: ${(props: CircleProps) =>
        props.type !== 'first' ? '100%' : props.theme.sizes.stepCircle};
    height: var(--circleSize);
    display: flex;
    align-items: center;
    span {
        display: inline-block;
        width: var(--circleSize);
        height: var(--circleSize);
        border: 1px solid var(--stepColor);
        border-radius: 50%;

        &::after {
            content: '';
            display: block;
            background-color: var(--stepColor);
            width: calc(var(--circleSize) / 2);
            height: calc(var(--circleSize) / 2);
            margin: calc((var(--circleSize) / 4) - 1px);
            border-radius: 50%;
        }
    }

    div {
        display: ${(props: CircleProps) =>
            props.type !== 'first' ? 'inline-block' : 'none'};
        height: 2px;
        width: calc(100% - var(--circleSize));
        background-color: var(--stepColor);
    }
`;

export const CircleGroup = styled.ul`
    list-style: none;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: ${(props: any) => props.theme.sizes.stepIndicator};
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        display: none;
    }
`;
