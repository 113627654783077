import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { benefits, states } from '../../utils/defaultValues';
import Header from '../../templates/header';

import Img from '../../assets/info-hundred.svg';
import { urls } from '../../utils/defaultValues.js';
import GlobalState from '../../hooks/globalState';
import api from '../../utils/api';
import useMobile from '../../hooks/useMobile';


import {
    Container, ModalHeader, Logo, DataCompany, ButtonEdit, EditData, DivContact,
    ButtonContact, ContainerDesktop, Introduction, Text, ButtonEditDesktop,
    ButtonContactDesktop, LogoDesktop, MainData, Company, Contact, Questions,
    ButtonBack
} from './styles';

const SolicitacaoCemMais = () => {
    const [client] = GlobalState.useGlobalState('client');
    const [quotation] = GlobalState.useGlobalState('quotation');

    const history = useHistory();
    const mobile = useMobile();


    const nameState = (argState: string | undefined) => {
        return states.filter(state => 
            state[1] === argState
        )[0]?.[0];
    }

    const nameBenefits = (argBenefits: string | undefined) => {
        benefits.filter(benefit => {
            if(benefit[1] === argBenefits){
                return benefit[0]
            }
        })
    }

    const companySize = nameBenefits(quotation.company_size)

    const contactSubmit = () => {
        history.push(urls.hundredThankYou)
    }

    const editSubmit = () => {
        history.push(urls.monteSeuPlano)
    }

    const goBack = () => {
        history.push(urls.seusDados)
    }

    const handleSubmit = () => {
        console.log(client)
        api.post(`/thank-you/${quotation.id}`);
        history.push(urls.hundredThankYou)
    }

    const mobileVersion = (
        <>
            <Container>
                <ModalHeader>
                    <Logo src="/img/dws-vector-white.svg" alt="logo" />

                    <h1>Agora falta pouco...</h1>
                    <p>Vimos que a sua empresa possui mais de 100 beneficiários. Para atender melhor as suas necessidades um dos nossos especialistas entrará em contato. Confirme seus dados</p>
                </ModalHeader>
                <DataCompany>
                    <h5>Dados da sua empresa</h5>
                    <small>Porte da sua empresa: <b>100 beneficiários</b></small>
                    <small>Estado: <b>{nameState(client.state)}</b></small>

                    <h5>Dados para contato</h5>
                    <small>Nome: <b>{client.name}</b></small>
                    <small>E-mail: <b>{client.email}</b></small>
                    <small>Telefone: <b>{client.phone}</b></small>

                    <EditData>
                            <ButtonEdit onClick={goBack} role="simpleInverted">
                                <span>Editar dados</span>
                            </ButtonEdit>
                    </EditData>

                </DataCompany>
                <DivContact>
                    <Link to={urls.hundredThankYou}>
                        <ButtonContact onClick={handleSubmit} role="primary">
                            <span>Solicitar contato</span>
                        </ButtonContact>
                    </Link>
                </DivContact>
            </Container>
        </>
    );


    const desktopVersion = (
        <>
            <Header relative />
            <ContainerDesktop>
                <Introduction>
                    <Text>
                        <strong>Agora falta pouco...</strong>
                        <p>Vimos que a sua empresa possui mais de 100 beneficiários. Para atender melhor as suas necessidades um dos nossos especialistas entrará em contato. Confirme seus dados</p>
                        <hr/>
                    </Text>
                    <LogoDesktop
                        src={Img}
                        alt="Info mais 100 beneficiários"
                    />
                </Introduction>
                <MainData>
                    <Company>
                        <p>Dados da sua empresa</p>
                        <small>Porte da sua empresa: <b>100 beneficiários</b></small>
                        <small>Estado: <b>{nameState(client.state)}</b></small>

                        <ButtonEditDesktop onClick={goBack} role="simpleInverted">
                            <span>Editar dados</span>
                        </ButtonEditDesktop>
                    </Company>
                    <Contact>
                        <p>Dados para contato</p>
                        <small>Nome: <b>{client.name}</b></small>
                        <small>E-mail: <b>{client.email}</b></small>
                        <small>Telefone: <b>{client.phone}</b></small>
                    </Contact>
                </MainData>
                <Questions>
                    <ButtonBack onClick={goBack} role="secondary">
                        <span>Voltar</span>
                    </ButtonBack>
                    <ButtonContactDesktop onClick={handleSubmit} role="primary">
                        <span>Solicitar contato</span>
                    </ButtonContactDesktop>
                </Questions>
            </ContainerDesktop>
        </>
    );



    return mobile ? mobileVersion : desktopVersion;

};

export default SolicitacaoCemMais;
