import GlobalState from '../hooks/globalState';

const addError = (name: string, message: string) => {
    const errorMap = new Map([
        ...GlobalState.getGlobalState('errors').entries(),
    ]);
    if (!errorMap.has(name)) {
        errorMap.set(name, message);
        GlobalState.setGlobalState('errors', errorMap);
    }
};

const addErrors = (errorMap: Map<string, string>) => {
    errorMap.forEach((value: any, key: any) => {
        addError(key, value);
    });
};

const remError = (name: string) => {
    const errorMap = new Map([
        ...GlobalState.getGlobalState('errors').entries(),
    ]);
    if (errorMap.delete(name)) {
        GlobalState.setGlobalState('errors', errorMap);
    }
};

const clearErrors = () => {
    GlobalState.setGlobalState('errors', new Map());
};

export { addError, addErrors, remError, clearErrors };
