import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import {
    states,
    benefits,
    multiInputValues,
    urls,
    ageRanges,
    existingPlanOptions,
} from '../../utils/defaultValues.js';
import GlobalState from '../../hooks/globalState';

import Header from '../../templates/header';
import Step from '../../templates/step';
import RadioGroup from '../../templates/radioGroup';
import MultiInputGroup from '../../templates/multiInputGroup';
import { addErrors, clearErrors } from '../../utils/errors';

import { validations, validate } from '../../utils/validation';
import executeGtm from '../../utils/gtm.js';
import AutoCompleteGroup from '../../templates/autoCompleteGroup';
import ProgressBar from '../../templates/progressBar';
import Head from '../../templates/head';

const MonteSeuPlano = () => {
    const [, setCookie] = useCookies();
    const history = useHistory();
    const values: any = [];

    const submitFormValues = (data: any, toNextPage: any) => {
        return new Promise((resolve: any, reject: any) => {
            const newData = { ...data };
            ageRanges.forEach((val: string) => {
                newData[val] = parseInt(newData[val], 10);
            });
            const valid = validate(newData, validations.step2);
            GlobalState.setGlobalState('errors', valid.errors);
            if (valid?.valid) {
                if (newData.has_plan === 'no') newData.existing_plan = '';
                delete newData.has_plan;
                const clientState = { state: newData.state };
                delete newData.state;
                GlobalState.setGlobalState('quotation', newData);
                GlobalState.setGlobalState('client', clientState);
                setCookie('lastPage', '0', { path: '/', sameSite: 'strict' });
                clearErrors();
                toNextPage();
                resolve(true);
            } else if (valid?.errors) {
                reject(Error('Erro'));
            }
        });
    };

    const sizeChange = (e: any, props: any, unhider: any) => {
        const isHidden = !['02_29', '30_99'].includes(e.target.value);
        unhider.setVisibility('benefits', !isHidden);
    };

    const sizeCustomUnhider = (props: any, unhider: any) => {
        let target;
        if (unhider.isLast() && !['02_29', '30_99'].includes(props.value)) {
            const {
                childrenmap: [map],
            } = unhider.context;
            target = map.get(map.get(props.index)?.[1])?.[1];
        }
        unhider.increment(true, target);
    };

    useEffect(() => {
        executeGtm(history.location.pathname);
    }, []);

    const description = (
        <>
            Para encontrarmos o melhor plano precisamos de algumas
            <br />
            informações.&nbsp;
            <b>É muito rápido.</b>
        </>
    );

    return (
        <>
            <Head pageName="Monte seu plano" />
            <Header />
            <ProgressBar quantity={3} pageId={0} />
            <Step
                pageId={0}
                name="Monte seu plano"
                stepName="step2"
                submit={submitFormValues}
                description={description}
                rightCol={{
                    image: './img/infoindicator.svg',
                    text: (
                        <>
                            As&nbsp;
                            <b>melhores seguradoras</b>
                            &nbsp;do mercado segundo avaliação da ANS.
                        </>
                    ),
                    security: true,
                }}
                nextPage={urls.seusDados}
                form
            >
                <RadioGroup
                    index="auto"
                    name="has_plan"
                    text="Possui plano de saúde?"
                    formName="has_plan"
                    radioList={[
                        ['Sim', 'yes'],
                        ['Não', 'no'],
                    ]}
                    activeValue="yes"
                    value={
                        Object.values(values).length
                            ? values.existing_plan
                                ? 'yes'
                                : 'no'
                            : undefined
                    }
                >
                    <AutoCompleteGroup
                        ref={React.createRef()}
                        name="existing_plan"
                        text="Qual plano?"
                        formName="existing_plan"
                        value={values?.existing_plan}
                        items={existingPlanOptions}
                        other={['Outro', 'Outro']}
                    />
                </RadioGroup>
                <AutoCompleteGroup
                    index="auto"
                    name="state"
                    text="Sua empresa está em qual estado?"
                    formName="state"
                    value={values?.state}
                    items={states}
                />
                <AutoCompleteGroup
                    index="auto"
                    customIncrement={sizeCustomUnhider}
                    name="company_size"
                    text="Qual o porte da sua empresa?"
                    formName="company_size"
                    value={values?.company_size}
                    onChange={sizeChange}
                    items={benefits}
                    globalErrors
                />
                <MultiInputGroup
                    index="benefits"
                    ref={React.createRef()}
                    name="multi_input"
                    description="Adicione a quantidade de beneficiários por faixa etária"
                    value={
                        values && values.company_size !== '100+'
                            ? ageRanges.map((key: string) => values[key] || 0)
                            : []
                    }
                    inputNames={multiInputValues}
                />
            </Step>
        </>
    );
};

export default MonteSeuPlano;
