import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import GlobalState from '../../hooks/globalState';
import Header from '../../templates/header';
import Step from '../../templates/step';
import { addErrors, addError, clearErrors } from '../../utils/errors';

import api from '../../utils/api';
import { validations, validate } from '../../utils/validation';

import { urls } from '../../utils/defaultValues.js';
import executeGtm from '../../utils/gtm.js';
import DynamicInputGroup from '../../templates/dynamicInputGroup';
import ProgressBar from '../../templates/progressBar';
import Tooltip from '../../templates/tooltip';
import { TitleContext, TitleProvider } from '../../hooks/useTitle';
import { useTimeout } from '../../hooks/useDebouncing';
import Head from '../../templates/head';

const SeusDados = () => {
    const client = GlobalState.getGlobalState('client');
    const quotation = GlobalState.getGlobalState('quotation');

    const [, setCookie] = useCookies();
    const history = useHistory();

    const submitFormValues = (fdata: any, toNextPage: any) => {
        return new Promise((resolve: any, reject: any) => {
            const re = /\d+/g;
            const data = { ...fdata };
            const { company_size } = quotation;
            data.cnpj = data.cnpj.match(re)?.join('');
            data.role = 'outro';
            const valid = validate(data, validations.step1);
            if (valid?.valid) {
                const clientData = { ...client, ...data };
                api.post('/clients', clientData)
                    .then((value: AxiosResponse<any>) => {
                        const responseData: any = value.data;
                        const newData = {
                            ...client,
                            ...data,
                            id: responseData.id,
                        };
                        const quotationData = {
                            ...quotation,
                            client: responseData.id,
                        };
                        api.post('/quotation', quotationData)
                            .then((qvalue: AxiosResponse<any>) => {
                                const qresponseData = qvalue.data;
                                GlobalState.setGlobalState('client', newData);
                                GlobalState.setGlobalState('quotation', {
                                    ...quotation,
                                    id: qresponseData.id,
                                });
                                if (company_size !== '100+') {
                                    toNextPage?.();
                                } else {
                                    history.push(urls.hundredSolicitation);
                                }
                            })
                            .catch((reason: any) => {
                                reject(reason);
                            });
                    })
                    .catch((reason: any) => reject(reason));
            } else if (valid?.errors) {
                reject(Error('erro'));
            }
        });
    };

    useEffect(() => {
        if (!Object.values(quotation).length) {
            history.push(urls.monteSeuPlano);
        }
        executeGtm(history.location.pathname);
    }, []);

    const icons = {
        email: (
            <i
                {...{ cursor: 'pointer', hover: true }}
                className="far fa-question-circle"
                data-tip="Este é um dos nossos principais canais de comunicação com você. Insira um email que você costuma acessar sempre."
                data-for="seus-dados-tooltip"
            />
        ),
    };

    const description = (
        <>
            Agora falta pouco! Para um&nbsp;
            <b>atendimento mais personalizado</b>
            <br />
            precisamos de alguns dados.
        </>
    );

    return (
        <>
            <Head pageName="Seus dados" />
            <Header />
            <ProgressBar quantity={3} pageId={1} />
            <TitleProvider
                title="name"
                defaultValue={client?.name}
                preprocessor={(str: string) => str.split(' ')[0]}
            >
                <Step
                    pageId={1}
                    name="Dados para contato"
                    stepName="step1"
                    description={description}
                    rightCol={{
                        image: './img/datainfoindicator.svg',
                        text: `As melhores opções de planos de acordo
                        com seu perfil.`,
                        security: true,
                    }}
                    nextPage={urls.compareEContrate}
                    previousPage={urls.monteSeuPlano}
                    form
                    submit={submitFormValues}
                >
                    <DynamicInputGroup
                        index="auto"
                        name="name"
                        text="Seu nome"
                        placeholder="Como gostaria de ser chamado?"
                        formName="name"
                        onDeboucing={(e, _, { title }) => {
                            title.setValue(e.target.value);
                        }}
                        targetName="email"
                        value={client?.name}
                    />
                    <DynamicInputGroup
                        index="auto"
                        name="email"
                        text="Email de #{name}"
                        icon={icons.email}
                        formName="email"
                        targetName="phone"
                        value={client?.email}
                    />
                    <DynamicInputGroup
                        index="auto"
                        name="phone"
                        text="Telefone de #{name}"
                        formName="phone"
                        value={client?.phone}
                        inputType="tel"
                        mask="(00) 00000-0000"
                        placeholder="(00) 00000-0000"
                        targetName="cnpj"
                    />
                    <DynamicInputGroup
                        index="auto"
                        name="cnpj"
                        text="CNPJ"
                        formName="cnpj"
                        targetName="role"
                        value={client?.cnpj}
                        inputType="tel"
                        placeholder="00.000.000/0000-00"
                        mask="00.000.000/0000-00"
                    />
                </Step>
            </TitleProvider>

            <Tooltip id="seus-dados-tooltip" />
        </>
    );
};

export default SeusDados;
