import React from 'react';
import styled, { css } from 'styled-components';

interface Radio extends React.HTMLProps<HTMLButtonElement> {
    checked?: boolean;
    theme?: any;
}

export const FlexDiv = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 35px;
`;

export const Group = styled.div`
    margin: 20px 0 0 0;
`;

export const Radio = styled.button<Radio>`
    @keyframes RadioEffect {
        0% {
            opacity: 0;
            width: 67.84%;
            height: 67.84%;
        }
        50% {
            opacity: 0.2;
            width: 175%;
            height: 175%;
        }
        100% {
            opacity: 0;
            width: 175%;
            height: 175%;
        }
    }
    display: inline-block !important;
    position: relative;
    min-width: 0px !important;
    width: 20px !important;
    height: 20px !important;
    margin: 0;
    margin-right: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 0;
    border: 1px solid ${(props: Radio) => props.theme.colors.darkblue};
    outline: none !important;
    &::after {
        content: '';
        display: block;
        width: 0px;
        height: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        background-color: ${(props: Radio) => props.theme.colors.yellow};
        padding: 0;
        margin: 0;
        border-radius: 50%;
        transition: width 0.2s ease, height 0.2s ease, opacity 0.2s ease;
    }

    &::before {
        display: none;
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${(props: Radio) => props.theme.colors.yellow};
        opacity: 1;
        border-radius: 50%;
        animation: RadioEffect 0.5s linear;
    }

    ${(props: any) =>
        props.checked &&
        css`
            border-color: ${(props: Radio) => props.theme.colors.yellow};

            &::after {
                width: 65%;
                height: 65%;
                opacity: 1;
            }
            &::before {
                display: block;
            }
        `}
`;

export const Label = styled.label`
    user-select: none;
    font-size: 16px;
    line-height: 16px;
    color: #686868 !important;
    margin: 0;
    padding: 0;
`;
export const NameLabel = styled.label`
    display: block;
    color: ${(props: any) => props.theme.colors.gray1};
`;
