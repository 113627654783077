import axios from 'axios';


function getBaseUrl() {

    switch(process.env.REACT_APP_ENV) {
        case 'development':
            return 'http://api.localhost/'
        case 'staging':
            return 'http://stagingapi.dwscorretora.com'
        default:
            return 'https://api.dwscorretora.com/'
    }
}


const api = axios.create({
    baseURL: getBaseUrl()
});

export default api;
