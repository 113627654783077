import React, { useEffect, useMemo, useState } from 'react';
import ButtonGroup from '../buttonGroup';
import useUnhider, { FormChildren } from '../../hooks/childrenUnhider';
import { Container, MobileButton, RotatingIcon } from './styles';
import leftArrow from '../../assets/left-arrow.svg';
import yellowLeftArrow from '../../assets/yellow-left-arrow.svg';

interface NavigationControlsProps extends FormChildren {
    next?: boolean;
    prev?: () => void; // function
    submit?: () => Promise<any>;
}

export const MobilePrevButton = (props: {
    prev?: () => void;
    darkVer?: boolean;
}) => {
    const { prev, darkVer } = props;
    return prev ? (
        <MobileButton type="button" onClick={prev}>
            <img src={darkVer ? leftArrow : yellowLeftArrow} alt="voltar" />
        </MobileButton>
    ) : null;
};

export const NavigationControls = (props: NavigationControlsProps) => {
    const unhider = useUnhider(props);
    const { prev, next, index, submit } = props;
    const [submitting, setSubmitting] = useState(false);

    async function submitForm() {
        setSubmitting(true);
        submit?.()?.catch(() => setSubmitting(false));
    }

    const buttons = useMemo(
        () => [
            prev ? (
                <ButtonGroup
                    key="prev"
                    ref={React.createRef()}
                    buttonrole="secondary"
                    text="Voltar"
                    onClick={prev}
                    inline
                />
            ) : null,
            next ? (
                <ButtonGroup
                    key="next"
                    index={index}
                    ref={React.createRef()}
                    hidden={unhider.hidden()}
                    text={
                        submitting ? (
                            <RotatingIcon className="fas fa-spinner" />
                        ) : (
                            'Próximo'
                        )
                    }
                    onClick={!submitting ? submitForm : undefined}
                    inline
                />
            ) : null,
        ],
        [submitting],
    );

    return <Container>{buttons}</Container>;
};
