import { Client, Quotation } from '../hooks/globalState';
import { ageRanges, states as stateEntries } from './defaultValues.js';

const states = Object.fromEntries(stateEntries.map(kv => [kv[1], kv[0]]));
const reverseStates = Object.fromEntries(stateEntries);

const sizes: any = {
    '02_29': 'de 02 a 29 beneficiários',
    '30_99': 'de 30 a 99 beneficiários',
    '100+': 'mais de 100 beneficiários',
};

type CompanyInfo = {
    size: () => string;
    benefitCount: () => number | '100+';
    state: () => string;
};

export function getState(uf: string) {
    return states[uf];
}

export function getReverseState(ufName: string) {
    return reverseStates[ufName];
}

export function getCompanyInfo(
    client: Client,
    quotation: Quotation,
): CompanyInfo {
    return {
        size: () => {
            const size: any = quotation.company_size;
            return sizes[size];
        },
        benefitCount: () => {
            const count = ageRanges
                .map((v: string) => {
                    const step: any = quotation;
                    return parseInt(step[v], 10);
                })
                .reduce((pv: any, current: any) => {
                    return pv + current;
                });
            return count >= 2 ? count : '100+';
        },
        state: () => {
            const { state }: any = client;
            return getState(state);
        },
    };
}
