import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';

import {
    Menu,
    MenuItem,
    MenuControls,
    MenuButton,
    MobileSelect,
    ChevronIcon,
} from './styles';
import { Input, Group } from '../dynamicInputGroup/styles';

import useUnhider, { FormChildren } from '../../hooks/childrenUnhider';
import { useError } from '../../hooks/useError';
import useMobile from '../../hooks/useMobile';
import scrollToMiddle from '../../utils/scrollToMiddle';
import useDebouncing from '../../hooks/useDebouncing';
import useEventListener from '../../hooks/useEventListener.js';

interface AutoCompleteGroupProps extends FormChildren {
    name: string; // Identificador para processamento de dados interno
    text?: string;
    formName?: string;
    focused?: boolean;
    placeholder?: string;
    value?: string;
    hidden?: boolean;
    onChange?: (e: Event, props: AutoCompleteGroupProps, unhider: any) => void;
    targetName?: string;
    items: Array<Array<string>>;
    other?: [string, string];
    error?: string;
    theme?: any;
    globalErrors?: boolean;
}

const toLower = (str: string) => {
    return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
};

const AutoCompleteGroup = React.forwardRef(
    (props: AutoCompleteGroupProps, ref: any) => {
        const {
            value,
            placeholder,
            formName,
            globalErrors,
            error: defaultError,
        } = props;

        const menuRef: any = React.createRef();

        const theme = useContext(ThemeContext);
        const breakpoint = parseInt(
            /[^(px)]+/.exec(theme.breakpoints.mobile)?.[0] || '0',
            10,
        );

        const { items } = props;

        const unhider = useUnhider(props);
        const mobile = useMobile();

        const getValue = (
            value: string,
            filterPos: number,
            caseSensitive?: boolean,
        ): Array<any> => {
            if (!value) return [];
            const item = [...items, ...[props.other || ['', '']]]
                .map((value: any, index: number) => [...value, index])
                .filter((v: string[]) =>
                    !caseSensitive
                        ? v[filterPos] === value
                        : toLower(v[filterPos]) ===
                          toLower(value.toLowerCase()),
                )[0];
            return item || [];
        };

        const [inputValue, setInputValue] = useState(
            getValue(value || '', 1, true)[0] || '',
        );

        const [trueValue, setTrueValue] = useState(value || '');
        const [selected, setSelected] = useState(-1);
        const [readOnly, setReadOnly] = useState(false);
        const [isActive, setActive] = useState(false);

        const inputRef: any = React.createRef();

        const scrollDebouncing = useDebouncing({
            callback: () => {
                const target = inputRef?.current;
                if (target) scrollToMiddle(target);
            },
            timeout: 300,
        });

        const errorManager = useError(
            formName || '',
            trueValue,
            [inputValue],
            globalErrors,
        );
        const error = errorManager.getError();

        const deActivate = () => setActive(false);

        const changeFunction = (e: any, activate = true) => {
            unhider.increment();
            const target: any = inputRef.current;
            const newTrueValue = getValue(target.value, 0, true)[1];
            if (e.type === 'click') {
                const currentSize = window.innerWidth;
                if (currentSize <= breakpoint) {
                    setInputValue('');
                    setTrueValue('');
                    activate && setActive(true);
                    setTrueValue('');
                    return;
                }
                activate && setActive(!isActive);
            } else {
                setSelected(-1);
                activate && setActive(true);
                setInputValue(target.value);
                setTrueValue(newTrueValue || '');
            }
            const newTarget = { ...target };
            newTarget.value = newTrueValue;
            e.target = newTarget;
            props.onChange?.(e, props, unhider);
        };

        const selectChange = (e: any) => {
            setInputValue(
                e.target.querySelector(`option[value='${e.target.value}']`)
                    ?.innerText || '',
            );
            setTrueValue(e.target.value);
            props.onChange?.(e, props, unhider);
        };

        const selectItemList = useMemo(() => {
            return items.map((value: string[]) => (
                <option key={value[1]} value={value[1]}>
                    {value[0]}
                </option>
            ));
        }, []);

        const itemList = useMemo(() => {
            const userInput = inputValue.toLowerCase();
            const clickEvt = (item: Array<string>[2]) => {
                return () => {
                    setInputValue(item[0]);
                    setTrueValue(item[1]);
                    setSelected(-1);
                    deActivate();
                };
            };
            const newItems: Array<any> = items
                .filter((value: any) =>
                    value[0].toLowerCase().includes(userInput),
                )
                .map((value: any, index: number) => {
                    return (
                        <MenuItem
                            text={value[0]}
                            active={index === selected}
                            onClick={clickEvt(value)}
                            key={value}
                        />
                    );
                });
            if (
                (newItems.length === 0 || inputValue.trim() === '') &&
                props.other
            ) {
                const value: any = props.other;
                const index = newItems.length;
                newItems.push(
                    <MenuItem
                        text={props?.other[0]}
                        onClick={clickEvt(value)}
                        key={index}
                        active={index === selected}
                    />,
                );
            }
            return newItems;
        }, [inputValue, selected]);

        const menu =
            isActive && itemList.length > 0 ? (
                <>
                    <MenuControls>
                        <MenuButton onClick={deActivate} type="button">
                            x
                        </MenuButton>
                    </MenuControls>
                    <Menu ref={menuRef}>{itemList}</Menu>
                </>
            ) : null;

        const inputHandler = (e: any) => {
            const key = e.keyCode;
            if ([38, 40, 13].includes(key)) {
                e.preventDefault();
                if (key === 13 && selected !== -1) {
                    itemList[selected].props.onClick();
                } else {
                    const up: any = key === 38;
                    const down: any = key === 40;
                    const direction: number = down - up;
                    let newSelected = selected + direction;
                    if (!isActive) setActive(true);

                    if (newSelected > itemList.length - 1) {
                        newSelected = 0;
                    } else if (newSelected < 0) {
                        newSelected = itemList.length - 1;
                    }
                    setSelected(newSelected);
                }
            }
        };

        const button = (
            <ChevronIcon
                className="fas fa-chevron-down"
                cursor="pointer"
                active={!mobile && isActive}
                onClick={mobile ? () => {} : changeFunction}
            />
        );

        useEffect(() => {
            if (!isActive) return;
            if (menuRef?.current && selected !== -1) {
                menuRef.current.scrollTop = selected * 28;
            }
        }, [selected]);

        useEffect(() => {
            if (!unhider.hidden()) {
                changeFunction(
                    { type: 'any', target: inputRef.current },
                    false,
                );
                inputRef.current.focus();
            }
        }, [trueValue]);

        useEventListener('click', () => {
            if (isActive) {
                setTimeout(() => {
                    if (window.innerWidth < breakpoint) return;
                    deActivate();
                }, 100);
            }
        });

        const onResize = () => {
            setReadOnly(window.innerWidth < breakpoint);
        };

        useEventListener('resize', onResize);

        useEventListener('scroll', () => scrollDebouncing.stop());

        useEffect(() => {
            if (props.focused) inputRef.current.focus();
            onResize();
        }, []);

        const mobileSelect = useMemo(() => {
            return mobile ? (
                <MobileSelect
                    name={formName}
                    value={trueValue}
                    onChange={selectChange}
                >
                    <option value="">{props.text}</option>
                    {selectItemList}
                    {props.other && (
                        <option value={props.other[1]}>{props.other[0]}</option>
                    )}
                </MobileSelect>
            ) : (
                <></>
            );
        }, [mobile, trueValue]);

        const switchInput = (
            <>
                <Input
                    ref={inputRef}
                    title={mobile ? '' : props.text}
                    onChange={changeFunction}
                    onKeyDown={inputHandler}
                    onFocus={() => {
                        scrollDebouncing.reset();
                    }}
                    onClick={changeFunction}
                    error={!!error}
                    autoComplete="off"
                    readOnly={readOnly}
                    haveIcon
                    type="search"
                    value={inputValue}
                    placeholder={placeholder}
                    hidden={mobile}
                />
                {mobileSelect}
                <span />
                <span />
            </>
        );

        return (
            <Group
                ref={ref}
                error={error}
                className="animatedInput"
                hidden={unhider.hidden()}
            >
                {button}
                {switchInput}
                {!mobile && menu}
                <div onClick={deActivate} />
                <input name={formName} value={trueValue} readOnly hidden />
            </Group>
        );
    },
);

export default AutoCompleteGroup;
