import React, { useState } from 'react';
import useUnhider, { FormChildren } from '../../hooks/childrenUnhider';
import { Group, Input, Button } from './styles';

interface ButtonInputGroup extends FormChildren {
    name?: string;
    formName?: string;
    value?: number;
    hidden?: boolean;
    onChange?: (e: Event, props: ButtonInputGroup) => void;
    index?: number;
}

function clamp(num: number, min: number, max: number): number {
    return Math.min(max, Math.max(min, num));
}

const ButtonInputGroup = React.forwardRef(
    (props: ButtonInputGroup, ref: any) => {
        const [inputValue, setInputValue] = useState(props.value || 0);

        const formName = props.formName || props.name;

        const inputRef: any = React.createRef();

        const unhider = useUnhider(props);

        function changeFunction(e: any, change = false) {
            if (!change) changeInputValue();
            unhider.increment();
            e.target = inputRef.current;
            props.onChange?.(e, props);
        }

        function changeInputValue(newInputValue?: string) {
            const value: string = newInputValue || inputRef.current.value;

            const filteredValue: string = value.replace(/[^\d]/g, '') || '0';

            const newValue: number = clamp(parseInt(filteredValue, 10), 0, 100);

            inputRef.current.value = newValue;

            setInputValue(newValue || 0);
        }

        function increment(e: any, offset = 1) {
            changeInputValue((inputValue + offset).toString());
            changeFunction(e, true);
        }

        return (
            <Group ref={ref} hidden={unhider.hidden()}>
                <Button
                    onClick={(e: any) => increment(e, -1)}
                    disabled={inputValue === 0}
                    type="button"
                >
                    -
                </Button>
                <Input
                    ref={inputRef}
                    name={formName}
                    onChange={changeFunction}
                    type="tel"
                    initial={inputValue === 0}
                    onClick={changeFunction}
                    value={inputValue}
                />
                <Button
                    onClick={increment}
                    disabled={inputValue === 100}
                    type="button"
                >
                    +
                </Button>
            </Group>
        );
    },
);

export default ButtonInputGroup;
