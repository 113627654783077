import React, { useState, useEffect } from 'react';
import useUnhider, { FormChildren } from '../../hooks/childrenUnhider';
import { NameLabel, Label, Radio, FlexDiv, Group } from './styles';

interface RadioGroupProps extends FormChildren {
    name: string;
    text?: string;
    formName?: string;
    radioList: Array<[string, string]>; // ex: [["Sim", "yes"], ["Não", "no"]]
    activeValue?: string;
    focused?: boolean;
    value?: string;
    hidden?: boolean;
    onChange?: (e: any, props: RadioGroupProps) => void;
    targetName?: string;
    children?: React.ReactNode;
}

const RadioGroup = React.forwardRef((props: RadioGroupProps, ref: any) => {
    const { name } = props;

    const [checkedValue, setChecked] = useState(props.value);

    const unhider = useUnhider(props);

    const formName = props.formName || name;

    const changeFunction = (e: any) => {
        const { type, value } = e.target;
        if (type === 'radio') setChecked(value);
        if (
            (type !== 'radio' && e.type !== 'click') ||
            (type === 'radio' && value !== props.activeValue)
        ) {
            unhider.increment();
            if (type === 'radio') {
                childrenVisibility(false);
            }
            props.onChange?.(e, props);
        } else {
            childrenVisibility(true);
        }
    };

    function childrenVisibility(visible: boolean) {
        children?.forEach((child: any) => {
            const element = child.ref?.current;
            if (element) {
                element.hidden = !visible;
            }
        });
    }

    const children = React.Children.map(
        props.children,
        (child: any, index: number) => {
            const newProps = { ...child.props };
            newProps.key = index;
            newProps.ref = React.createRef();
            newProps.onChange = changeFunction;
            return React.cloneElement(child, newProps, child.props.children);
        },
    );

    const radioList = props.radioList?.map((radioDef: Array<string>) => {
        const [name, value] = radioDef;
        const change = () => {
            const ev = { target: { type: 'radio', value } };
            changeFunction(ev);
        };
        return (
            <FlexDiv key={value}>
                <input
                    ref={React.createRef()}
                    onChange={changeFunction}
                    type="radio"
                    checked={value === checkedValue}
                    name={formName}
                    value={value}
                    hidden
                />
                <Radio
                    checked={value === checkedValue}
                    type="button"
                    onClick={change}
                />
                <Label>{name}</Label>
            </FlexDiv>
        );
    });

    useEffect(() => {
        childrenVisibility(
            props.value ? props.value === props.activeValue : false,
        );
    }, []);

    return (
        <Group ref={ref} className="animatedInput" hidden={unhider.hidden()}>
            <NameLabel>{props.text}</NameLabel>
            {radioList}
            {children}
        </Group>
    );
});

export default RadioGroup;
