import { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FilterContext } from '..';
import GlobalState from '../../../hooks/globalState';
import Tooltip from '../../../templates/tooltip';
import api from '../../../utils/api';

import {
    FilterDiv,
    FilterSection,
    FilterSectionTitle,
    ContainerTitle,
    FilterHeader,
    ExitIcon,
    ExitButton,
    Star,
    ApplyButton,
    ButtonSection,
    FilterSectionDiv,
    FilterButton,
    SearchField,
    BackgroundWindow,
    SearchFieldDiv,
    SearchIcon,
} from './styles';

const toLower = (str: string) => {
    return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
};

const FilterBlock = (props: {
    title?: string;
    internalName: string;
    list: Array<Array<string>>;
    isSwitch?: boolean;
    isSearchable?: boolean;
}) => {
    const { title, internalName, isSwitch, list, isSearchable } = props;
    const [searchValue, setSearchValue] = useState('');
    const [filters, setFilters] = useState(list.slice(0, 8));
    const [, , filterMap] = useContext(FilterContext);
    const filterList = filters.map((entries: Array<string>) => {
        const [key, value] = entries;
        const isActive =
            filterMap.has(internalName) &&
            filterMap.get(internalName).has(value);
        const activateSwitch = () => {
            if (filterMap.has(internalName)) {
                const obj = filterMap.get(internalName);
                if (obj.has(value)) {
                    if (obj.size === 1) {
                        filterMap.delete(internalName);
                    } else {
                        obj.delete(value);
                    }
                } else {
                    if (isSwitch) {
                        obj.clear();
                    }
                    obj.add(value);
                }
            } else {
                filterMap.set(internalName, new Set([value]));
            }
            filterMap.apply();
        };
        const isLong = key.length >= 28;
        const tooltipObj = isLong
            ? {
                  'data-tip': key,
                  'data-for': 'filter-tooltip',
              }
            : {};
        return (
            <FilterButton
                key={key}
                onClick={activateSwitch}
                active={isActive}
                {...tooltipObj}
                isLong={isLong}
            >
                {key}
            </FilterButton>
        );
    });

    const onSearchChange = (e: any) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (!isSearchable) return;
        const thisSet = filterMap.get(internalName);

        if (searchValue === '') {
            const filteredList = new Set([
                ...list.slice(0, 8),
                ...list.filter(
                    (value: string[]) => thisSet && thisSet.has(value[1]),
                ),
            ]);
            setFilters(Array.from(filteredList));
        } else {
            const filteredList = list.filter((value: string[]) => {
                return (
                    toLower(value[0]).includes(toLower(searchValue)) ||
                    (thisSet && thisSet.has(value[1]))
                );
            });
            setFilters(filteredList.slice(0, 8));
        }
    }, [searchValue]);

    return (
        <FilterSection>
            {title && !isSearchable && (
                <FilterSectionTitle>
                    <p>{title}</p>
                </FilterSectionTitle>
            )}
            {isSearchable && title && (
                <SearchFieldDiv>
                    <SearchIcon
                        {...{ hover: true, cursor: 'pointer' }}
                        className="fas fa-search"
                    />
                    <SearchField
                        title={title}
                        onChange={onSearchChange}
                        value={searchValue}
                        haveIcon
                    />
                    <span />
                    <span />
                </SearchFieldDiv>
            )}
            <FilterSectionDiv>{filterList}</FilterSectionDiv>
        </FilterSection>
    );
};

const Filter = () => {
    const quotation = GlobalState.getGlobalState('quotation');
    const [activeState, appliedState, filterMap, clearState] = useContext(
        FilterContext,
    );
    const [filterIsActive, setFilterActive] = activeState;
    const [applied, setApplied] = appliedState;
    const [cleared, setCleared] = clearState;
    const [hospitals, setHospitals] = useState([] as string[][]);

    const isFilterOrClearable = filterMap.map.size !== 0 || !applied;

    useEffect(() => {
        if (!cleared) setApplied(false);
    }, [filterMap.map]);

    useEffect(() => {
        async function req(
            list: string[][],
            page: number,
            hasNext: boolean,
        ): Promise<string[][]> {
            if (hasNext) {
                try {
                    const response: AxiosResponse<any> = await api.get(
                        `/plans/hospitals/${quotation.id}?page=${page}`,
                    );
                    const { data } = response;
                    const hospitalData = data.results.map(
                        (value: { name: string; pk: number }) => [
                            value.name,
                            `${value.pk}`,
                        ],
                    );
                    return req(
                        [...list, ...hospitalData],
                        page + 1,
                        data.next !== null,
                    );
                } catch (e) {
                    return list || [];
                }
            } else {
                return list || [];
            }
        }

        req([], 1, true).then((hospitalData: string[][]) => {
            setHospitals(hospitalData);
        });
    }, []);

    const clearFilters = () => {
        filterMap.clear();
        filterMap.apply();
        setCleared(true);
        setApplied(!applied);
        setFilterActive(false);
    };

    const starList: any = Array.from(Array(5).keys()).map((num: number) => {
        const starNum = num + 1;
        return [
            Array(starNum)
                .fill(null)
                .map((value: any, index: number) => (
                    <Star key={`${num}-${index}`} className="fas fa-star" />
                )),
            starNum.toString(),
        ];
    });

    return (
        <>
            <BackgroundWindow
                open={filterIsActive}
                onClick={() => setFilterActive(false)}
            />
            <FilterDiv open={filterIsActive}>
                <FilterHeader>
                    <ExitButton onClick={() => setFilterActive(false)}>
                        <ExitIcon src="./img/times.svg" />
                    </ExitButton>
                    <ContainerTitle>Filtrar Planos</ContainerTitle>
                </FilterHeader>
                <FilterBlock
                    title="Seguradora"
                    internalName="company"
                    list={[
                        ['Amil', 'Amil'],
                        ['Bradesco', 'Bradesco'],
                        ['SulAmérica', 'SulAmérica'],
                    ]}
                />

                <FilterBlock
                    title="Abrangência"
                    internalName="coverage"
                    isSwitch
                    list={[
                        ['Nacional', 'NT'],
                        ['Regional', 'RG'],
                    ]}
                />

                <FilterBlock
                    title="Acomodação"
                    internalName="accommodation"
                    isSwitch
                    list={[
                        ['Apartamento', 'AP'],
                        ['Enfermaria', 'EF'],
                    ]}
                />

                <FilterBlock
                    title="Classificação"
                    internalName="star"
                    list={starList}
                />

                <FilterBlock
                    title="Coparticipação"
                    internalName="partaking"
                    isSwitch
                    list={[
                        ['Sim', 'true'],
                        ['Não', 'false'],
                    ]}
                />

                <FilterBlock
                    title="Cobertura"
                    internalName="coverage_table"
                    isSwitch
                    list={[
                        ['Completo', 'completo'],
                        ['Hospitalar', 'hospitalar'],
                    ]}
                />

                {hospitals.length > 0 && (
                    <>
                        <FilterBlock
                            title="Principais Hospitais"
                            internalName="hospitals_pk"
                            isSearchable
                            list={hospitals}
                        />
                        <Tooltip id="filter-tooltip" />
                    </>
                )}

                <ButtonSection
                    open={filterIsActive}
                    active={isFilterOrClearable}
                >
                    <ApplyButton role="secondary" onClick={clearFilters}>
                        Limpar
                    </ApplyButton>
                    <ApplyButton
                        disabled={applied}
                        onClick={() => {
                            setApplied(true);
                            setFilterActive(false);
                        }}
                    >
                        Filtrar
                    </ApplyButton>
                </ButtonSection>
            </FilterDiv>
        </>
    );
};

export default Filter;
