/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';

interface Progress extends React.HTMLProps<HTMLDivElement> {
    progress?: number;
    theme?: any;
}

export const Progress = styled.div<Progress>`
    content: '';
    background-color: ${(props: Progress) => props.theme.colors.gray3};
    position: absolute;
    display: block;
    height: 6px;
    z-index: 1;
    margin: 0;
    padding: 0;
    left: 0;
    width: 100vw;

    &::after {
        content: '';
        height: 6px;
        width: ${(props: Progress) => `${props.progress}vw`};
        display: block;
        position: absolute;
        background-color: ${(props: Progress) => props.theme.colors.yellow};
    }

    @media (min-width: ${(props: Progress) => props.theme.breakpoints.mobile}) {
        display: none;
    }
`;
