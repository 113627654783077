import React from 'react';
import Helmet from 'react-helmet';

interface Head {
    pageName?: string;
    simulation?: boolean;
    closedwon?: boolean;
}

const Head = (props: Head) => {
    const { pageName, simulation, closedwon } = props;
    const pageTitle = `Dws Cotador${pageName ? `: ${pageName}` : ''}`;

    return (
        <Helmet>
            <title>{pageTitle}</title>
            <script>
                {`            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js',
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-MDSXL49');`}
            </script>
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-974410537"
            />
            <script>
                {`
                window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'AW-974410537');
            `}
            </script>
            {simulation ? (
                <script>
                    {`
                        gtag('event', 'conversion', {'send_to': 'AW-974410537/wWmBCNyQ6NQBEKmm0dAD'});
                    `}
                </script>
            ) : (
                <script />
            )}
            {closedwon ? (
                <script>
                    {`
                          gtag('event', 'conversion', {
                            'send_to': 'AW-974410537/bVPTCKuHk_ABEKmm0dAD',
                            'transaction_id': ''
                        });
                    `}
                </script>
            ) : (
                <script />
            )}
            <script>
                {`
                    !(function (f, b, e, v, n, t, s) {
                        if (f.fbq) return;
                        n = f.fbq = function () {
                            n.callMethod
                                ? n.callMethod.apply(n, arguments)
                                : n.queue.push(arguments);
                        };
                        if (!f._fbq) f._fbq = n;
                        n.push = n;
                        n.loaded = !0;
                        n.version = '2.0';
                        n.queue = [];
                        t = b.createElement(e);
                        t.async = !0;
                        t.src = v;
                        s = b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t, s);
                    })(
                        window,
                        document,
                        'script',
                        'https://connect.facebook.net/en_US/fbevents.js',
                    );
                    fbq('init', '197003813971772');
                    fbq('track', 'PageView');
                `}
            </script>
        </Helmet>
    );
};

export default Head;
