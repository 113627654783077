import React from 'react';
import { Progress } from './styles';

interface ProgressBar {
    quantity: number;
    pageId: number;
}

const ProgressBar = (props: ProgressBar) => {
    const { quantity, pageId } = props;
    const progress: number = Math.floor((100 / quantity) * (pageId + 1));
    return <Progress progress={progress} />;
};

export default ProgressBar;
