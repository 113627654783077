function separate(arr, size) {
    if (arr.length < size) return arr.length !== 0 ? [arr] : [];
    return [arr.slice(0, size), ...separate(arr.slice(size), size)];
}

function transform(num) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
    });
    return formatter.format(num);
}

export default transform;
