import React from 'react';
import styled, { css } from 'styled-components';

interface Button extends React.HTMLProps<HTMLButtonElement> {
    theme?: any;
    role?:
        | 'primary'
        | 'secondary'
        | 'action'
        | 'knowmore'
        | 'simple'
        | 'simpleInverted';
}

interface Group extends React.HTMLProps<HTMLDivElement> {
    theme?: any;
    inline?: boolean;
}

export const Group = styled.div<Group>`
    position: relative;
    ${(props: Group) =>
        props.hidden &&
        css`
            display: none !important;
        `}
    ${(props: Group) =>
        props.inline &&
        css`
            display: inline-block !important;
            margin-right: 5px;
            padding-top: 0px !important;
            padding-top: 0px !important;
        `}
`;

export const Button = styled.button<Button>`
    outline: none !important;
    min-width: 177px !important;
    padding: 15px 0px;
    position: relative;
    border-radius: 6px;
    color: ${(props: Button) => props.theme.colors.darkblue};
    border: none;
    box-shadow: 0px 3px 5px rgba(33, 36, 41, 0.16);
    transition: all 0.3s ease;
    display: inline;

    &::after {
        content: '';
        position: absolute;
        display: none;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 4px;
        background-color: transparent;
        transition: all 0.3s ease;
        box-shadow: inset 0px 3px 5px rgba(33, 36, 41, 0.1);
    }

    ${(props: Button) => {
        let newCss;
        switch (props.role) {
            case 'action':
                newCss = css`
                    padding: 14.5px 0px;
                    background-color: ${props.theme.colors.yellow};
                    border: 1px solid ${props.theme.colors.yellow};
                    &:hover {
                        box-shadow: none;
                        background-color: transparent;
                    }
                `;
                break;
            case 'knowmore':
                newCss = css`
                    background-color: transparent;
                    padding: 14.5px 0px;
                    border: 1px solid ${props.theme.colors.yellow};
                    box-shadow: none;
                    &::after {
                        display: block;
                    }
                `;
                break;
            case 'simpleInverted':
                newCss = css`
                    background-color: transparent;
                    color: ${props.theme.colors.darkblue};
                    border-bottom: 1px solid ${props.theme.colors.yellow};
                    border-radius: 0;
                    box-shadow: none;
                    min-width: 0 !important;
                    padding: 9.5px;
                    font-weight: 400;
                    @media (max-width: ${(props: any) =>
                            props.theme.breakpoints.mobile}) {
                        padding: 5.5px;
                    }
                `;
                break;
            case 'simple':
                newCss = css`
                    background-color: transparent;
                    color: ${props.theme.colors.yellow};
                    border-bottom: 1px solid ${props.theme.colors.darkblue};
                    border-radius: 0;
                    box-shadow: none;
                    min-width: 0 !important;
                    padding: 9.5px;
                    font-weight: 400;
                    @media (max-width: ${(props: any) =>
                            props.theme.breakpoints.mobile}) {
                        padding: 7.5px;
                    }
                `;
                break;
            case 'secondary':
                newCss = css`
                    background-color: transparent;
                    padding: 14.5px 0px;
                    border: 1px solid ${props.theme.colors.darkblue};
                    box-shadow: none;
                    /*
                    &::after {
                        box-shadow: none;
                        display: block;
                    }
                    &:hover {
                        box-shadow: inset 0px 3px 5px rgba(33, 36, 41, 0.3) !important;

                        &::after {
                            box-shadow: inset 0px 3px 5px rgba(33, 36, 41, 0.3) !important;
                        }
                    } */
                `;
                break;
            case 'primary':
            default:
                newCss = css`
                    background-color: ${props.theme.colors.yellow};
                `;
        }
        return newCss;
    }}

    &:hover {
        &::after {
            box-shadow: none;
        }
        box-shadow: none;
    }

    ${(props: Button) =>
        props.disabled &&
        css`
            background-color: ${props.theme.colors.gray2} !important;
            color: rgba(255, 255, 255, 0.5) !important;
            border-color: rgb(104, 104, 104) !important;
            box-shadow: none;
        `}
`;
