import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../templates/buttonGroup/styles';

export const PlanDiv = styled.div`
    @keyframes planAnim {
        from {
            filter: opacity(0);
        }
        to {
            filter: opacity(1);
        }
    }

    animation: 0.6s linear 0s planAnim;
    width: 100%;
    padding: 18px 28px 0px 28px;
    display: flex;
    flex-direction: row;
    border-left: 2px solid ${(props: any) => props.theme.colors.yellow};
    box-shadow: 0px 3px 4px rgba(38, 50, 70, 0.1);
    margin-bottom: 12px;
    p {
        text-align: left !important;
    }
`;

export const PlanCol = styled.div<{ grow?: number }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    ${(props: any) =>
        props.grow !== undefined &&
        css`
            flex-grow: ${props.grow};
        `}
`;

export const PlanSection = styled.section`
    color: ${(props: any) => props.theme.colors.gray1};
    height: 63px;
    margin-bottom: 10px;
    h3 {
        font-weight: 400;
    }
    small {
        color: ${(props: any) => props.theme.colors.gray2};
    }
    .stars {
        margin-top: 6px;
    }
`;

export const InnerModal = styled.div<{ active?: boolean }>`
    position: fixed;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 0;
    right: -100vw;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: #fff;
    transition: right 0.8s ease, opacity 0.6s ease;
    scroll-behavior: smooth;
    ${(props: any) =>
        props.active &&
        css`
            opacity: 1;
            right: 0;
        `}
`;

export const ModalContainer = styled.div<{ noPadding?: boolean }>`
    margin-bottom: 80px;
    ${(props: any) =>
        !props.noPadding &&
        css`
            padding: 18px 8vw;
            margin: 0;
        `};
    background-color: #fff;
`;

export const ModalHeader = styled(ModalContainer)`
    background-color: ${(props: any) => props.theme.colors.darklightblue};
    *:not(i) {
        color: #fff !important;
    }
    i {
        color: ${(props: any) => props.theme.colors.yellow};
    }
    padding-bottom: 80px;
`;

export const ModalRow = styled.div<{ marginTop?: string }>`
    display: flex;
    width: 100%;
    flex-direction: row;

    p {
        text-align: left !important;
    }

    ${(props: any) =>
        props.marginTop &&
        css`
            margin-top: ${props.marginTop};
        `}
`;

export const Logo = styled.img`
    width: ${(props: any) => props.theme.sizes.logo};
    margin-bottom: 30px;
`;

export const ChevronIcon = styled.i<
    { active?: boolean } & React.HTMLProps<HTMLLIElement>
>`
    color: ${(props: any) => props.theme.colors.darkblue};
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    transition: transform 0.3s ease;
    ${(props: any) =>
        props.active &&
        css`
            transform: rotateZ(-180deg);
        `};
`;

export const TogglerContainer = styled.div`
    display: flex;
    p {
        font-size: 18px;
    }
    justify-content: space-between;
    padding: 0;

    flex-direction: row;
    * {
        margin: 0;
    }
`;

export const CollapsibleContainer = styled.div`
    padding: 0;
    padding-top: 10px;
    small {
        color: ${(props: any) => props.theme.colors.gray2};
    }
`;

export const CollapseContainer = styled.div`
    box-shadow: 0px 3px 6px rgba(38, 50, 70, 0.12);
    width: 100%;
    padding: 30px 8vw;
    margin-bottom: 8px;
    * {
        text-align: left;
        margin: 0;
    }
`;

export const EndButton = styled(Button)`
    width: 84vw;
`;

export const EndButtonContainer = styled.div<{ active?: boolean }>`
    position: fixed;
    display: block;
    background-color: #fff;
    width: 100vw;
    right: -100vw;
    bottom: 0;
    padding: 10px 8vw;
    transition: opacity 0.3s ease;
    opacity: 0;
    box-shadow: 0px -3px 5px rgba(38, 50, 70, 0.1);
    ${(props: any) =>
        props.active &&
        css`
            right: 0;
            opacity: 1;
        `}
`;

export const Observations = styled.div`
    display: flex;
    width: 100%;
    padding: 10px 8vw;
    color: ${(props: any) => props.theme.colors.gray2};
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    * {
        margin: 0;
        font-size: 12px;
    }
    i {
        font-size: 30px;
        margin-right: 20px;
    }
`;
