import React, { useState, useContext, useMemo } from 'react';

export type TitleContext = {
    title: string;
    value: string;
    setValue: (value: string) => void;
    preprocessor: (str: string) => string;
};

export type TitleContextResponse = {
    formattedStr: string;
    setValue: (value: string) => void;
};

export const TitleContext = React.createContext<TitleContext>(
    {} as TitleContext,
);

export const TitleProvider = (
    props: React.PropsWithChildren<{
        title: string;
        defaultValue?: string;
        preprocessor?: (str: string) => string;
    }>,
) => {
    const { title, defaultValue, preprocessor: pre, children } = props;
    const [value, setValue] = useState<string>(defaultValue || '');

    const preprocessor = pre || ((str: string) => str);

    return (
        <TitleContext.Provider value={{ title, value, setValue, preprocessor }}>
            {children}
        </TitleContext.Provider>
    );
};

export function useTitle(formatStr: string): TitleContextResponse {
    const ctx = useContext<TitleContext>(TitleContext);

    const formattedStr: string = useMemo(
        () =>
            ctx
                ? formatStr.replace(
                      RegExp(`#{${ctx.title}}`),
                      ctx.preprocessor(ctx.value).replace(/\\/g, '\\\\'),
                  )
                : formatStr,
        [ctx.value],
    );
    return {
        formattedStr,
        setValue: ctx?.setValue || (() => {}),
    };
}
