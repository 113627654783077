import styled, { css } from 'styled-components';
import { Button } from '../../../templates/buttonGroup/styles';
import { Radio } from '../../../templates/radioGroup/styles';
import {
    Input,
    Group,
    Icon,
} from '../../../templates/dynamicInputGroup/styles';

type FilterButton = { active?: boolean; isLong?: boolean };

export const FilterDiv = styled.div<{ open?: boolean }>`
    display: flex;
    flex-direction: column;
    overflow-x: visible;
    overflow-y: auto;
    position: fixed;
    background-color: #fff;
    z-index: 2;
    height: 100vh;
    padding-bottom: 68px;
    width: 350px;
    top: 0;
    left: -350px;
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0);
    transition: left 0.8s ease, box-shadow 0.3s ease;

    ${(props: any) =>
        props.open &&
        css`
            left: 0 !important;
            box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.2);
        `}

    * {
        user-select: none;
    }

    scrollbar-width: none;
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props: any) => props.theme.colors.darklightblue};
    }

    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        width: 100vw;
        left: -100vw;
    }
`;

export const FilterHeader = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
    padding-bottom: 0px;
    position: relative;
    border-radius: 0 0 10px 10px;
    transition: all 0.8s ease;
`;

export const ContainerTitle = styled.h5`
    margin-bottom: 30px;
    text-align: left;
    font-weight: 300;
`;

export const Container = styled.div`
    display: flex;
    margin: 0 40px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
`;

export const ExitIcon = styled.img`
    width: 14px;
    height: 14px;
    transition: all 0.3s ease;
    /* filter: drop-shadow(1px 2px 1px #3339); */
`;

export const ExitButton = styled.button`
    position: absolute;
    top: 40px;
    right: 40px;
    border: none;
    outline: none !important;
    background-color: transparent;
    &:hover {
        * {
            filter: drop-shadow(1px 2px 0 #0000);
        }
    }
`;

export const ClearButton = styled.button`
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: 12px;
    color: ${(props: any) => props.theme.colors.yellow};
    outline: none !important;
    border: none;
    background-color: transparent;
`;

export const FilterSection = styled.div`
    padding: 25px 40px 0 40px;
    * {
        margin: 0;
    }
`;

export const FilterSectionTitle = styled.div`
    p {
        font-size: 16px;
        margin: 0;
        font-weight: 300;
        color: ${(props: any) => props.theme.colors.gray7};
    }
    border-bottom: 1px solid ${(props: any) => props.theme.colors.gray3};
    padding-bottom: 10px;
    margin-bottom: 20px;
`;

export const FilterButton = styled.button<FilterButton>`
    --buttonColor: ${(props: any) =>
        props.theme.colors[props.active ? 'yellow' : 'gray7']};
    padding: 9px 18px;
    display: inline;
    background-color: #fff;
    position: relative;
    white-space: nowrap;
    word-break: keep-all;
    overflow-x: hidden;
    font-size: 14px;
    /* line-height: 14px; */
    font-weight: 300;
    color: ${(props: any) => props.theme.colors.darklightblue};
    background-color: transparent;
    border: 1px solid var(--buttonColor);
    border-radius: 0.3rem;
    box-shadow: 1px 1px 3px #0003;
    margin: 0 16px 16px 0;
    max-width: 100%;
    outline: none !important;
    transition: all 0.5s ease;
    i {
        transition: all 0.5s ease;
        color: var(--buttonColor);
    }

    ${(props: FilterButton) =>
        !props.active &&
        css`
            color: var(--buttonColor);
            box-shadow: none;
            /* &:hover {
                color: ${(props: any) => props.theme.colors.gray1};
                border-color: ${(props: any) => props.theme.colors.gray1};
            } */
        `}

    ${(props: FilterButton) =>
        props.isLong &&
        css`
            &::after {
                content: '';
                background-color: #fff;
                position: absolute;
                border-radius: 2px;
                width: 8px;
                height: 50%;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                box-shadow: -6px 0px 5px white;
            }
        `}
`;

export const FilterSectionDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ChevronIcon = styled.i<{ active?: boolean }>`
    position: absolute;
    font-size: 20px;
    color: ${(props: any) => props.theme.colors.yellow};
    transition: transform 0.5s ease;
    ${(props: any) =>
        props.active &&
        css`
            transform: scaleY(0);
        `}
`;

export const ChevronGroup = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
`;

export const SectionToggler = styled.div`
    display: flex;
    padding: 20px;
    cursor: pointer;
    width: 100%;
    h5 {
        margin: 0;
    }
    justify-content: space-between;
    align-items: center;
`;

export const CheckBoxGroup = styled.div`
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    cursor: pointer;
    * {
        user-select: none;
    }
    &:last-child {
        margin-bottom: 0px;
    }
`;

export const CheckBoxLabel = styled.p`
    margin-bottom: 0;
    margin-left: 10px;
`;

export const CheckBoxInput = styled(Radio)`
    border-radius: 2px;
    &::after {
        content: '\f00c';
        font-size: 12px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        line-height: 22px;
        color: #fff;
        border-radius: 2px;
    }
    &::before {
        border-radius: 10px;
    }
    ${(props: any) =>
        props.checked &&
        css`
            &::after {
                width: 100% !important;
                height: 100% !important;
                border-radius: 0;
            }
        `}
`;

export const ButtonSection = styled.div<{ open?: boolean; active: boolean }>`
    padding: 14px 40px;
    height: 68px;
    width: 345px;
    left: -345px;
    bottom: -68px;
    display: flex;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0);
    transition: left 0.8s ease, box-shadow 0.3s ease, bottom 0.3s ease;

    ${(props: any) =>
        props.open &&
        css`
            left: 0 !important;
            box-shadow: -2px -3px 10px rgba(0, 0, 0, 0.2);
        `}

    ${(props: any) =>
        props.active &&
        css`
            bottom: 0;
        `}

    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        width: 100vw;
        left: -100vw;
    }
`;

export const ApplyButton = styled(Button)`
    flex: 1;
    &:first-child {
        margin-right: 30px;
        font-weight: 300;
    }

    padding: 10px 0px;
    min-width: 0px !important;
`;

export const SearchField = styled(Input)`
    border-bottom-color: ${(props: any) => props.theme.colors.gray3};
    color: ${(props: any) => props.theme.colors.gray7};
    font-weight: 300;
    & + span + span::after {
        font-weight: 300;
        color: ${(props: any) => props.theme.colors.gray7};
    }
`;

export const SearchFieldDiv = styled(Group)`
    margin-bottom: 15px;
    width: 100% !important;
`;

export const SearchIcon = styled(Icon)`
    color: ${(props: any) => props.theme.colors.gray7};
`;

export const BackgroundWindow = styled.div<{ open?: boolean }>`
    content: '';
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #0003;
    z-index: 1;
    pointer-events: ${(props: any) => (props.open ? 'auto' : 'none')};
    opacity: ${(props: any) => (props.open ? 1 : 0)};
    transition: opacity 0.5s ease;
`;

export const Star = styled.i`
    font-size: 12px;
`;
