import React, { useEffect, useMemo, useState } from 'react';
import imask from 'imask';
import IMaskInput from './masks.js';
import { Input, Group, Icon } from './styles';
import useUnhider, { FormChildren } from '../../hooks/childrenUnhider';
import { useError } from '../../hooks/useError';
import { TitleContextResponse, useTitle } from '../../hooks/useTitle';
import scrollToMiddle from '../../utils/scrollToMiddle';
import useDebouncing from '../../hooks/useDebouncing';

type EventFunction = (
    e: any,
    props: DynamicInputGroupProps,
    controls: {
        unhider: any;
        title: TitleContextResponse;
    },
) => void;

interface DynamicInputGroupProps extends FormChildren {
    name: string;
    text?: string;
    formName?: string;
    focused?: boolean;
    placeholder?: string;
    value?: any;
    inputType?: string;
    hidden?: boolean;
    onChange?: EventFunction;
    onBlur?: EventFunction;
    onDeboucing?: EventFunction;
    targetName?: string;
    mask?: string;
    error?: string;
    autocomplete?: boolean;
    icon?: any; // component
    properties?: React.HTMLProps<HTMLInputElement>;
}

const getMaskedValue = (value?: string, mask?: string) => {
    if (!mask) return value;
    const masked = imask.createMask({
        mask,
    });
    return masked.resolve(value || '');
};

const DynamicInputGroup = React.forwardRef(
    (props: DynamicInputGroupProps, ref: any) => {
        const {
            name,
            inputType,
            value,
            placeholder,
            mask,
            focused,
            onChange,
            onBlur,
            text: oldText,
            autocomplete,
            formName: fname,
            icon: iconComponent,
            properties,
            onDeboucing,
        } = props;

        const inputRef: any = React.createRef();

        const unhider = useUnhider(props);

        const title = useTitle(oldText || '');

        const { formattedStr: text } = title;
        const [inputValue, setInputValue] = useState(
            getMaskedValue(value, mask) || '',
        );

        const deboucing = useDebouncing({
            callback: () =>
                onDeboucing?.(
                    { target: inputRef?.current, type: 'deboucing' },
                    props,
                    {
                        unhider,
                        title,
                    },
                ),
            timeout: 300,
        });

        const getInputRefTarget = () => {
            if (mask) {
                return inputRef?.current?.element;
            }
            return inputRef?.current;
        };

        const scrollDebouncing = useDebouncing({
            callback: () => {
                const target = getInputRefTarget();
                if (target) scrollToMiddle(target);
            },
            timeout: 300,
        });

        const errorDeboucing = useDebouncing({ timeout: 3000 });

        const errorManager = useError(fname || name, inputValue, [
            errorDeboucing.active,
        ]);

        const error = errorDeboucing.active ? errorManager.getError() : null;

        const formName = fname || name;

        const changeFunction = (e: any) => {
            if (e.type === 'change') {
                if (onDeboucing) deboucing.reset();
                errorDeboucing.reset();
            }
            unhider.increment();
            setInputValue(e.target.value);
            onChange?.(e, props, {
                unhider,
                title,
            });
        };

        const acceptFunction = (value: string, mask: any) => {
            const e = { target: getInputRefTarget() };
            if (onDeboucing) deboucing.reset();
            errorDeboucing.reset();
            unhider.increment();
            setInputValue(value);
            onChange?.(e, props, {
                unhider,
                title,
            });
        };

        const blurFunction = (e: any) => {
            onBlur?.(e, props, {
                unhider,
                title,
            });
        };

        const icon = useMemo(
            () => (iconComponent ? <Icon {...iconComponent.props} /> : null),
            [],
        );

        // useEffect(() => {
        //     if (focused) inputRef.current.focus();

        //     if (mask) {
        //         imask(inputRef.current, { mask });
        //     }
        // }, []);

        useEffect(() => {
            const scrollEvent = () => {
                scrollDebouncing.stop();
            };
            window.addEventListener('scroll', scrollEvent);
            return () => {
                window.removeEventListener('scroll', scrollEvent);
            };
        }, []);

        const inputComponent = (
            <Input
                ref={inputRef}
                title={text}
                onFocus={() => {
                    scrollDebouncing.reset();
                }}
                onBlur={blurFunction}
                name={formName}
                onChange={changeFunction}
                onClick={changeFunction}
                error={!!error}
                {...(autocomplete ? { autoComplete: 'off' } : {})}
                haveIcon={!!icon}
                type={inputType || 'text'}
                value={inputValue}
                placeholder={placeholder}
                {...(properties || [])}
            />
        );

        const getMaskedComponent = () => {
            if (!mask) {
                return inputComponent;
            }
            const maskedProps = { ...inputComponent.props };
            maskedProps.mask = mask;
            maskedProps.onAccept = acceptFunction;
            maskedProps.ref = inputRef;
            delete maskedProps.onChange;
            delete maskedProps.onClick;
            return <IMaskInput {...maskedProps} />;
        };

        return (
            <Group
                ref={ref}
                error={error}
                className="animatedInput"
                hidden={unhider.hidden()}
            >
                {icon}
                {getMaskedComponent()}
                <span />
                <span />
            </Group>
        );
    },
);

export default DynamicInputGroup;
