import styled, { css } from 'styled-components';

import { Button } from '../../templates/buttonGroup/styles';


export const Container = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    margin-bottom: 80px;
    background-color: #fff;

    a {
        text-decoration: none;
    }

`
export const ModalHeader = styled.header`
    padding: 13px 20px;
    width: 100%;

    background-color: ${(props: any) => props.theme.colors.darklightblue};
    * {
        color: #fff;
    }
    i {
        color: ${(props: any) => props.theme.colors.yellow};
    }
    padding-bottom: 80px;
    height: 376px;

    h1 {
        margin-top: 61px;
        width: 257px;
        height: 29px;
        font-size: 24px;
    }

    p {
        padding-top: 25px;
        width: 282px;
        height: 120px;
        padding-bottom: 69px;
    }
`;

export const Logo = styled.img`
    width: ${(props: any) => props.theme.sizes.logo};
    margin-bottom: 30px;
`;

export const DataCompany = styled.div`
    height: 100vh;
    width: 400px;
    padding-left: 26px;

    display: flex;
    flex-direction: column;

    h5 {
        margin-top: 45px;
    }

    small {
        display: block;
        margin-top: 11px;
    }
`;

export const EditData = styled.div`
    margin-top: 41px;
    width: 112px;

`;

export const ButtonEdit = styled(Button)`
    width: 100%;
    display: block;
    height: 50px;
    line-height: 56px;
    font-size: 16px;
    text-decoration: none;
`;

export const DivContact = styled.div`
    height: 80px;
    width: 378px;
    margin-top: 80px;
    margin-bottom: 38px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonContact =  styled(Button)`
    width: 255px;
    height: 50px;
`;

export const ContainerDesktop = styled.div`
    width: 100vw;
    height: 100vh;
    padding-top: 150px;
    padding-left: 13.91vw;
    padding-right: 13.91vw;
`;

export const Introduction = styled.div`
    display: flex;
    flex-direction: row;

    hr {
        height: 1px;
        width: 900px;
        margin-top: 38px;
        background: rgba(196, 196, 196, 0.5);
    }
`;

export const Text = styled.div`
    max-width: 670px;
    display: flex;
    flex-direction: column;

    strong {
        font-size: 28px;
        font-weight: bold;
        line-height: 56px;
        letter-spacing: 0.3px;
        color: #263246;
    }

    p {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: normal;
        margin-top: 20px;
        letter-spacing: 0.3px;
        line-height: 30px;
        color: #263246;
    }
`;

export const LogoDesktop = styled.img`
    margin-left: 40px;
    width: 240px;
    height: 240px;
    z-index: 10;
`;

export const MainData = styled.div`
    width: 1036px;
    height: 185px;
    display: flex;
    margin-top: 67px;
`;

export const Company = styled.div`
    display: flex;
    flex-direction: column;

    p {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.3px;
        line-height: 56px;
        color: #263246;
        margin-bottom: 21px;
    }

    small {
        font-weight: 500px;
        line-height: 40px;
        font-size: 18px;
        letter-spacing: 0.3px;
    }


`;

export const Contact = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 145px;

    p {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.3px;
        line-height: 56px;
        color: #263246;
        margin-bottom: 21px;
    }

    small {
        font-weight: 500px;
        line-height: 40px;
        font-size: 18px;
        letter-spacing: 0.3px;
    }
`;

export const ButtonEditDesktop = styled(Button)`
    margin-top: 70px;
    width: 120px;
    text-align: left;
`;

export const ButtonContactDesktop = styled(Button)`
    padding-left: 40px;
    padding-right: 40px;
    width: 232px;
`;

export const Questions = styled.div`
    margin-top: 224px;
    height: 220px;
`;

export const ButtonBack = styled(Button)`
    width: 177px;
    margin-right: 292px;
`;
