import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

const useMobile = () => {
    const [mobile, setMobile] = useState(false);
    const theme = useContext(ThemeContext);

    useEffect(() => {
        const changeLayout = () => {
            const breakpoint = parseInt(
                /[^(px)]+/.exec(theme.breakpoints.mobile)?.[0] || '0',
                10,
            );
            setMobile(window.innerWidth <= breakpoint);
        };
        changeLayout();

        window.addEventListener('resize', changeLayout);
        return () => {
            window.removeEventListener('resize', changeLayout);
        };
    }, []);

    return mobile;
};

export default useMobile;
