import React from 'react';
import styled, { css } from 'styled-components';

interface DivProps {
    tinyMargin?: boolean;
    theme?: any;
}

interface CompoundDivProps extends DivProps, React.HTMLProps<HTMLDivElement> {}
interface CompoundFormProps
    extends DivProps,
        React.HTMLProps<HTMLFormElement> {}

const divCss = css`
    margin-top: calc(8vh + 100px);
    display: flex;
    position: relative;
    font-weight: bolder;
    padding-bottom: 200px;
    ${(props: DivProps) =>
        props.tinyMargin &&
        css`
            margin-top: calc(8vh + 20px) !important;
        `}
    @media (max-width: ${(props: DivProps) => props.theme.breakpoints.mobile}) {
        margin-top: 20px;
    }
`;

export const MainDiv = styled.div<CompoundDivProps>`
    ${divCss}
`;
export const MainForm = styled.form<CompoundFormProps>`
    ${divCss}
`;

export const LeftCol = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 72.18vw;
    margin-left: 13.91vw;
    margin-right: 13.91vw;
    color: #263246;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        width: 100%;
        margin-left: 8vw !important;
        margin-right: 8vw !important;
    }
`;

export const RightCol = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    position: absolute;
    right: 0;
    top: 260px;
    @media (max-width: 1013px) {
        display: none !important;
    }
`;

export const IndicatorImage = styled.img`
    width: 160px;
`;

export const IndicatorText = styled.p`
    margin-top: 20px;
    max-width: 70%;
    color: ${(props: any) => props.theme.colors.gray1};
`;

export const SecurityDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    * {
        margin: 0;
        color: ${(props: any) => props.theme.colors.gray1};

        padding: 0;
    }

    img {
        margin-right: 17px;
    }
`;

export const Title = styled.h2`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 90%;
    color: ${(props: any) => props.theme.colors.darklightblue};
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        margin-top: 0;
        font-weight: 500;
        font-size: 28px;
    }
`;

export const Description = styled.p`
    margin-bottom: 40px;
    width: 90%;
    color: ${(props: any) => props.theme.colors.gray1};
`;
