import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../templates/buttonGroup/styles';

interface Row extends React.HTMLProps<HTMLDivElement> {
    first?: boolean;
    justify?: string;
    shadow?: boolean;
    marginTop?: string;
    coverScreen?: boolean;
}

interface Th {
    last?: boolean;
    first?: boolean;
}

export const Container = styled.div`
    color: ${(props: any) => props.theme.colors.gray1};
    padding-top: 53px;
    padding-left: 13.91vw;
    padding-right: 13.91vw;
    display: block;
    position: relative;
    font-weight: bolder;
    padding-bottom: 200px;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        padding-left: 8vw;
        padding-right: 8vw;
        padding-top: 20px;
    }
`;

export const Row = styled.div<Row>`
    display: flex;
    position: relative;
    justify-content: ${(props: Row) => props.justify || 'space-between'};
    align-items: center;
    margin-top: 50px;
    ${(props: Row) =>
        props.first &&
        css`
            align-items: flex-start;
            margin-top: 37px;
        `}
    ${(props: Row) =>
        props.shadow &&
        css`
            box-shadow: 0px 4px 4px rgba(38, 50, 70, 0.06);
        `}
    ${(props: Row) =>
        props.coverScreen &&
        css`
            width: 100vw;
            margin-left: -13.91vw;
            @media (max-width: ${(props: any) =>
                    props.theme.breakpoints.mobile}) {
                margin-left: -8vw;
            }
        `}
    ${(props: Row) =>
        props.marginTop &&
        css`
            margin-top: ${props.marginTop} !important;
        `}

    ${(props: Row) =>
        props.height &&
        css`
            height: ${props.height} !important;
        `}
`;

export const Col = styled(Row)`
    flex-direction: column;
`;

export const PrevButton = styled(Button)`
    padding: 9px 0px;
    min-width: 112.23px !important;
    border-radius: 5px;
`;

export const FilterContainer = styled.div`
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    padding: 20px 13.91vw 20px 13.91vw;
    position: relative;
    width: 100%;
    div {
        width: 350px !important;
    }
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        padding: 20px 0px 0px !important;
        margin-top: 0;
    }
`;

export const FilterButton = styled.button`
    --filterColor: ${(props: any) => props.theme.colors.gray1};
    color: var(--filterColor);
    font-size: 16px;
    white-space: nowrap;
    font-weight: 300;
    background-color: transparent;
    outline: none !important;
    padding: 9px 0px;
    min-width: 112.23px;
    border-radius: 5px;
    border: 1px solid var(--filterColor);
    transition: all 0.4s ease;
    i {
        color: var(--filterColor);
        transition: color 0.4s ease;
        margin-left: 5px;
    }
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        padding: 9px 15px;
        margin-right: 35px;
        min-width: 0px !important;
        width: 100%;
        margin-bottom: 10px;
    }
`;

export const OrderByContainer = styled.div`
    /* margin-right: 14px; */
    display: flex;
    flex: 1;
    position: relative;
    padding-right: 15px;
    justify-content: flex-end;
    align-items: center;

    --filterColor: ${(props: any) => props.theme.colors.gray1};

    h5 {
        color: ${(props: any) => props.theme.colors.gray1};
        font-size: 14px;
        line-height: 14px;
        padding-right: 29px;
        font-weight: 300;
        margin-bottom: 0;
        white-space: nowrap;
        word-break: keep-all;
    }

    i {
        color: var(--filterColor);
        position: absolute;
        right: 25px;
        transition: color 0.4s ease;
    }

    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        /* margin-right: 0; */
    }
`;

export const OrderBy = styled.select`
    color: var(--filterColor);
    * {
        font-size: 16px;
        line-height: 16px;
        font-weight: 300;
    }
    white-space: nowrap;
    font-weight: 300;
    background-color: transparent;
    outline: none !important;
    padding: 9px 28px;
    padding-left: 25px;
    border-radius: 5px;
    line-height: 16px;
    font-size: 16px;
    height: 38px;
    border: 1px solid ${(props: any) => props.theme.colors.gray1};
    transition: all 0.4s ease;
    select::-ms-expand {
        display: none;
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        border: 1px solid ${(props: any) => props.theme.colors.gray1};

        text-align: center;
        text-align-last: center;
        -moz-text-align-last: center;
    }
`;

export const ShowMoreButtonDiv = styled.div`
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const LeftCol = styled.div`
    color: ${(props: any) => props.theme.colors.gray1};
    h3 {
        font-weight: 400 !important;
        margin-bottom: 25px;
    }
`;

export const UserName = styled.span`
    color: ${(props: any) => props.theme.colors.yellow};
`;

export const CompanyInfo = styled.div`
    display: flex;
    margin-top: 63px;
    margin-bottom: 45px;
`;

export const InfoBlock = styled.div`
    display: block;
    margin-right: 36px;
`;

export const InfoTitle = styled.small`
    font-weight: 500;
`;

export const InfoValue = styled.p`
    background-color: #e9e9e9;
    margin-top: 15px;
    padding: 9px 16px;
    line-height: 14px;
    border-radius: 200px;
`;

export const RightCol = styled.div`
    display: flex;
    width: 15vw;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
`;

export const IndicatorImage = styled.img`
    width: 160px;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        display: none;
    }
`;

export const PlanTable = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const Th = styled.div<Th>`
    flex: 1;
    * {
        text-align: left !important;
    }

    ${(props: Th) =>
        props.first &&
        css`
            * {
                padding-left: 24px;
            }
        `}
    ${(props: Th) =>
        props.last &&
        css`
            flex-basis: 100px;
            flex-grow: 0;
        `}
`;

export const PlanTableHead = styled.div`
    background-color: ${(props: any) => props.theme.colors.darklightblue};
    color: white;
    display: grid;
    height: 80px !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    grid-column-gap: 24px;
    grid-template-columns: repeat(6, 1fr);

    /* border-radius: 5px 5px 0 0; */
    box-shadow: 0px 0px 10px rgba(38, 50, 70, 0.4);

    p {
        line-height: 16px;
        margin: 0;
        text-align: center;
    }
`;

export const SpinnerContainer = styled.div`
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
// React.HTMLProps<HTMLImageElement> &
export const Spinner = styled.img<{ active?: boolean }>`
    opacity: 0;
    width: 0;
    height: 0;
    transition: opacity 0.5s ease;
    ${(props: any) =>
        props.active &&
        css`
            opacity: 1;
            width: 70px;
            height: 70px;
        `}
`;

export const ToTopButton = styled.div<{ active?: boolean }>`
    width: 48px;
    height: 48px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 50px;
    right: 80px;
    ${(props: { active?: boolean }) => css`
        opacity: ${props.active ? 1 : 0};
        cursor: ${props.active ? 'pointer' : 'default'};
        pointer-events: ${props.active ? 'auto' : 'none'};
    `}
    transition: opacity 0.5s ease;
    user-select: none;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        bottom: 20px;
        right: 20px;
    }
`;

export const ToTopButtonIcon = styled.img`
    width: 35px;
    height: 35px;
    transform: rotateZ(90deg);
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        bottom: 30px;
        right: 40px;
        width: 28px;
        height: 28px;
    }
`;
