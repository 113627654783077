import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const StyledTooltip = styled(ReactTooltip)`
    padding: 12px 14px;
    max-width: 300px;
    color: ${(props: any) => props.theme.colors.gray1} !important;
    font-family: ${(props: any) => props.theme.font.family}, sans-serif;
    font-size: 12px;
    box-shadow: -5px 10px 10px #0001;
    border-radius: 5px;
    background-color: white !important;
    &.place-top {
        &::after {
            border-top-color: white;
        }
    }
    &.place-bottom {
        &::after {
            border-top-color: white;
        }
    }
    &.place-left {
        &::after {
            border-top-color: white;
        }
    }
    &.place-right {
        &::after {
            border-top-color: white;
        }
    }
`;

export default StyledTooltip;
