import React, { useContext, useEffect, useMemo, useState } from 'react';
import useDebouncing from '../../../hooks/useDebouncing';
import ButtonGroup from '../../../templates/buttonGroup';
import {
    Collapsible,
    Toggler,
    ActiveContext,
} from '../../../templates/collapse';
import Tooltip from '../../../templates/tooltip';
import transform from '../../../utils/monetizer.js';
import {
    PlanBody,
    PlanRow,
    PlanCol,
    PlanTitle,
    Icon,
    PlanView,
    PlanViewCol,
    PlanViewRow,
    Hospital,
    HospitalDiv,
    Star,
    StarDiv,
    PlanSection,
    ButtonPlanSection,
    ObsPlanSection,
    TextBlock,
    PlanViewGrid,
} from './styles';

export type Plan = {
    id: any;
    name: string;
    company: {
        id: number;
        name: string;
        description: string | null;
        dependents: string;
    };
    accommodation?: string;
    reimbursement?: number;
    partaking?: boolean;
    coverage?: string;
    tableCoverage?: string;
    hospitals?: Array<string>;
    diff?: string;
    total?: number;
    saude?: number;
    dental?: number;
    observations?: string;
    ranking?: number;
    iof?: number;
    modality?: string;
    lack?: any;
    onChoose?: (id: number, props: Plan) => void;
};

const IconButton = () => {
    const [open] = useContext(ActiveContext);
    return <Icon active={open} className="fa fa-chevron-down" />;
};

export const Stars = (props: { ranking?: number }) => {
    const { ranking } = props;
    const rank: number = Math.floor(ranking || 0);

    const stars = Array(5)
        .fill(null)
        .map((_: any, index: number) => (
            <Star key={index} className="fas fa-star" active={index < rank} />
        ));
    return <StarDiv className="stars">{stars}</StarDiv>;
};

const DescriptionBlock = (props: { active: boolean; text?: string }) => {
    const { active, text } = props;
    return (
        <TextBlock active={active}>
            <p>{text}</p>
        </TextBlock>
    );
};

const PlanHeaderRow = (
    props: React.PropsWithChildren<any> & { bodyRef: any },
) => {
    const { children, bodyRef } = props;
    const [open] = useContext(ActiveContext);
    const debounce = useDebouncing({
        callback: () => {
            if (open) bodyRef?.current?.scrollIntoView(true);
        },
        timeout: 500,
    });

    useEffect(() => {
        debounce.reset();
    }, [open]);

    return <PlanRow>{children}</PlanRow>;
};

const calculateIof = (iof: number, total: number): number => {
    return (iof / 100) * total;
};

const Plan = (props: Plan) => {
    const {
        id,
        name,
        company,
        accommodation,
        reimbursement,
        partaking,
        coverage,
        hospitals,
        tableCoverage,
        diff,
        lack,
        total,
        saude,
        dental,
        observations,
        ranking,
        iof: iofValue,
        modality,
        onChoose,
    } = props;

    const iof = iofValue ? calculateIof(iofValue, total || 0) : 0;

    const [activeTextBlock, setActiveTextBlock] = useState<number | null>(null);

    const hospitalList = hospitals?.map((name: any) => (
        <Hospital key={name}>{name}</Hospital>
    ));

    function chooseHandler() {
        return onChoose?.(id, props);
    }

    const bodyRef: any = React.createRef();

    const grid1 = [
        'saude partaking . coverage coverage coverage',
        'odonto modality . lacks lacks lacks',
        'iof reimbursement . lacks lacks lacks',
        'dependents dependents . hospitals hospitals hospitals',
    ];

    return (
        <PlanBody ref={bodyRef}>
            <Toggler index={id} containerTrigger>
                <PlanHeaderRow bodyRef={bodyRef}>
                    <PlanCol first>
                        <div>
                            <PlanTitle company={company.name}>{name}</PlanTitle>
                        </div>
                    </PlanCol>
                    <PlanCol>{coverage}</PlanCol>
                    <PlanCol>{accommodation}</PlanCol>
                    <PlanCol>
                        <Stars ranking={ranking} />
                    </PlanCol>
                    <PlanCol>
                        R$&nbsp;
                        {transform(total || 0)}
                    </PlanCol>
                    <PlanCol last>
                        <IconButton />
                    </PlanCol>
                </PlanHeaderRow>
            </Toggler>
            <Collapsible index={id}>
                <PlanView>
                    {/* <PlanViewRow> */}
                    <PlanViewGrid grid={grid1}>
                        <PlanSection first name="saude">
                            <p>
                                <b>Saúde</b>
                            </p>
                            <p>
                                R$&nbsp;
                                {transform(saude || 0)}
                            </p>
                        </PlanSection>
                        <PlanSection first name="odonto">
                            <p>
                                <b>Odonto</b>
                            </p>
                            <p>
                                R$&nbsp;
                                {transform(dental || 0)}
                            </p>
                        </PlanSection>
                        <PlanSection first name="iof">
                            <p>
                                <b>
                                    IOF&nbsp;
                                    <i
                                        className="fas fa-question-circle text-muted"
                                        data-tip="Imposto sobre operações financeiras."
                                        data-for={`plan-${id}-planview-tooltip`}
                                    />
                                </b>
                            </p>
                            <p>
                                R$&nbsp;
                                {transform(iof)}
                            </p>
                        </PlanSection>
                        <PlanSection name="partaking">
                            <p>
                                <b>Coparticipação</b>
                            </p>
                            <p>{partaking ? 'Sim' : 'Não'}</p>
                        </PlanSection>
                        <PlanSection name="modality">
                            <p>
                                <b>
                                    Modalidade&nbsp;
                                    <i
                                        className="fas fa-question-circle text-muted"
                                        data-tip="Compulsória: Inclusão de todo o grupo segurável.
                                        Opcional: Não há obrigatoriedade de inclusão de todo o grupo segurável."
                                        data-for={`plan-${id}-planview-tooltip`}
                                    />
                                </b>
                            </p>
                            <p>{modality}</p>
                        </PlanSection>
                        <PlanSection name="reimbursement">
                            <p>
                                <b>
                                    Reembolso&nbsp;
                                    <i
                                        className="fas fa-question-circle text-muted"
                                        data-tip="Valor de reembolso por consultas médicas."
                                        data-for={`plan-${id}-planview-tooltip`}
                                    />
                                </b>
                            </p>
                            <p>
                                R$&nbsp;
                                {transform(reimbursement || 0)}
                            </p>
                        </PlanSection>
                        <PlanSection name="coverage">
                            <p>
                                <b>Cobertura</b>
                            </p>
                            <p>{tableCoverage}</p>
                        </PlanSection>
                        <PlanSection name="lacks">
                            <p>
                                <b>Carências</b>
                            </p>
                            <p>
                                {`${lack}`
                                    .split('\n')
                                    .map(
                                        (
                                            value: string,
                                            index: number,
                                            array: string[],
                                        ) => {
                                            const newLine =
                                                index < array.length ? (
                                                    <br />
                                                ) : null;
                                            return (
                                                <React.Fragment
                                                    key={index * array.length}
                                                >
                                                    {value}
                                                    {newLine}
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                            </p>
                        </PlanSection>
                        <PlanSection first name="dependents">
                            <p>
                                <b>Quem pode ser dependente</b>
                            </p>
                            <p>{company.dependents}</p>
                        </PlanSection>
                        <PlanSection name="hospitals">
                            <p>
                                <b>Principais hospitais</b>
                            </p>
                            <div>
                                <HospitalDiv>{hospitalList}</HospitalDiv>
                            </div>
                        </PlanSection>
                    </PlanViewGrid>
                    <ButtonPlanSection>
                        <ButtonGroup
                            text="Diferenciais"
                            buttonrole="simpleInverted"
                            properties={{
                                className: 'mr-3',
                                onMouseEnter: () => {
                                    setActiveTextBlock(0);
                                },
                                onMouseLeave: () => {
                                    setActiveTextBlock(null);
                                },
                            }}
                        />
                        <ButtonGroup
                            text={`Sobre a ${company.name}`}
                            buttonrole="simpleInverted"
                            properties={{
                                onMouseEnter: () => {
                                    setActiveTextBlock(1);
                                },
                                onMouseLeave: () => {
                                    setActiveTextBlock(null);
                                },
                            }}
                        />
                    </ButtonPlanSection>
                    <PlanViewRow>
                        <DescriptionBlock
                            active={activeTextBlock === 0}
                            text={diff || ''}
                        />
                        <DescriptionBlock
                            active={activeTextBlock === 1}
                            text={company.description || ''}
                        />
                    </PlanViewRow>
                    <PlanViewRow last>
                        <PlanViewCol grow={2}>
                            <ObsPlanSection>
                                <i className="fas fa-question-circle text-muted" />
                                <small>{observations}</small>
                            </ObsPlanSection>
                        </PlanViewCol>
                        <PlanViewCol
                            grow={1}
                            justify="flex-end"
                            padding={{ right: '24px' }}
                        >
                            <PlanSection>
                                <ButtonGroup
                                    text="Contratar Plano"
                                    onClick={chooseHandler}
                                />
                            </PlanSection>
                        </PlanViewCol>
                    </PlanViewRow>
                    <Tooltip id={`plan-${id}-planview-tooltip`} />
                </PlanView>
            </Collapsible>
        </PlanBody>
    );
};

export default Plan;
