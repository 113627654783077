import React, { useMemo, useEffect } from 'react';
import useUnhider, { FormChildren } from '../../hooks/childrenUnhider';
import scrollToMiddle, { offsetTop } from '../../utils/scrollToMiddle';
import ButtonInputGroup from '../buttonInputGroup';
import { Group, Table, TableBody, TableCol, Description } from './styles';

interface MultiInputGroupProps extends FormChildren {
    name: string;
    description?: string;
    hidden?: boolean;
    onChange?: any;
    targetName?: string;
    inputNames?: Array<Array<string>>;
    value?: Array<number>;
    stepRef?: any;
}

const MultiInputGroup = React.forwardRef(
    (props: MultiInputGroupProps, ref: any) => {
        const { description, value, inputNames } = props;
        const unhider = useUnhider(props);

        const changeFunction = (e: any) => {
            unhider.increment();
            props.onChange?.(e, props);
        };

        useEffect(() => {
            const isHidden = unhider.hidden();
            if (!isHidden) {
                const target = ref?.current;
                if (target) {
                    window.scrollTo(0, offsetTop(target) - 100);
                }
            }
        }, [unhider.hidden()]);

        const inputs = useMemo(
            () =>
                inputNames?.map((values: Array<string>, index: number) => {
                    const [key, val] = values;
                    return (
                        <tr key={key}>
                            <TableCol width="50%">
                                <ButtonInputGroup
                                    name={key}
                                    onChange={changeFunction}
                                    formName={val}
                                    value={value?.[index]}
                                />
                            </TableCol>
                            <TableCol width="50%">
                                <p>{key}</p>
                            </TableCol>
                        </tr>
                    );
                }),
            [value, inputNames],
        );

        return (
            <Group
                ref={ref}
                className="animatedInput"
                hidden={unhider.hidden()}
            >
                <Description>{description}</Description>
                <Table>
                    <TableBody>{inputs}</TableBody>
                </Table>
            </Group>
        );
    },
);

export default MultiInputGroup;
