import React, { useContext, useEffect, useState } from 'react';
import { UnmountClosed as CollapsibleDiv } from 'react-collapse';

interface Toggler {
    children?: any;
    index: string | number;
    containerTrigger?: boolean;
    triggerContainerComponent?: any;
    defaultState?: boolean;
}

interface Collapsible {
    children?: any;
    index: string | number;
}

interface Collapse {
    children?: any;
    accordion?: boolean;
}

const Context: any = React.createContext(new Array<any>(3).fill(null));
export const ActiveContext = React.createContext(
    new Array<any>([null, null]),
);

export const Toggler = (props: Toggler) => {
    const {
        index,
        children,
        containerTrigger,
        triggerContainerComponent,
    } = props;
    const [stateMap, setStateMap, accordion] = useContext(Context);
    const switchState = () => {
        const newState = new Map(stateMap);
        if (accordion && !newState.get(index)) {
            if (!newState.has(index)) newState.set(index, false);
            [...newState.keys()].forEach((key: any) => {
                newState.set(key, key === index);
            });
        } else {
            newState.set(index, !newState.get(index));
        }
        setStateMap(newState);
    };

    const TriggerComponent = React.createElement(
        triggerContainerComponent || 'div',
        { onClick: switchState },
        children,
    );

    return (
        <ActiveContext.Provider value={[!!stateMap.get(index), switchState]}>
            {containerTrigger ? TriggerComponent : children}
        </ActiveContext.Provider>
    );
};

export const Collapsible = (props: Collapsible) => {
    const { index, children } = props;
    const [stateMap] = useContext(Context);
    return (
        <CollapsibleDiv isOpened={!!stateMap.get(index)}>
            {children}
        </CollapsibleDiv>
    );
};

export const Collapse = (props: Collapse) => {
    const { accordion, children } = props;
    const [stateMap, setStateMap] = useState(
        new Map<string | number, boolean>(),
    );

    useEffect(() => {
        const newMap = new Map(stateMap);
        React.Children.forEach(children, (child: any) => {
            const { index, defaultState } = child.props;
            if (child.type === Toggler)
                newMap.set(index, defaultState || false);
        });
        setStateMap(newMap);
    }, [children]);

    return (
        <Context.Provider value={[stateMap, setStateMap, accordion]}>
            {children}
        </Context.Provider>
    );
};
