import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Router from './routes';
import GlobalStyle from './utils/globalStyle.js';
import { defaultTheme } from './utils/themes';

const App = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <Router />
        </ThemeProvider>
    );
};

export default App;
