import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../templates/header';
import { urls } from '../../utils/defaultValues.js';
import './styles.scss';

const InvalidPage = (props: { type?: string }) => {
    const elementObj: any = {
        notFound: {
            iconName: 'far fa-file-excel',
            title: 'Link inválido',
            linkUrl: urls.monteSeuPlano,
            linkText: 'Clique aqui',
            text: 'para voltar à página inicial do cotador',
        },
        invalidToken: {
            iconName: 'fas fa-file-signature',
            title: 'Cotação expirada',
            linkUrl: urls.monteSeuPlano,
            linkText: 'Clique aqui',
            text: 'e atualize a cotação do seu seguro saúde',
        },
    };

    const { type } = props;

    const values: any = elementObj[type || ''] || elementObj.notFound;

    return (
        <>
            <Header relative />
            <div className="invalidPage w-100 h-100">
                <i className={`${values.iconName} crossIcon`} />
                <h1>{values.title}</h1>
                <div className="invalidBody">
                    <Link to={values.linkUrl} className="titleUrl">
                        {values.linkText}
                    </Link>
                    <p>{values.text}</p>
                </div>
            </div>
        </>
    );
};

export default InvalidPage;
