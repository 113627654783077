import { useState } from 'react';

function useMapState<K, V>(initialValue: Map<K, V>, dynamic?: boolean) {
    const [mapState, setMapState] = useState(initialValue || new Map<K, V>());

    function updateMap(map: Map<K, V>) {
        const newMap = new Map<K, V>(map);
        setMapState(newMap);
    }

    return {
        map: mapState,
        set: (key: K, value: V) => {
            const returnVal = mapState.set(key, value);
            if (dynamic) updateMap(mapState);
            return returnVal;
        },
        get: (key: K) => {
            return mapState.get(key);
        },

        has: (key: K) => {
            return mapState.has(key);
        },
        delete: (key: K) => {
            const returnVal = mapState.delete(key);
            if (dynamic) updateMap(mapState);
            return returnVal;
        },
        clear: () => {
            const returnVal = !!mapState.size;
            mapState.clear();
            if (dynamic) updateMap(mapState);
            return returnVal;
        },
        apply: () => updateMap(mapState),
    };
}

export default useMapState;
