import styled, { css } from 'styled-components';

export const MainDiv = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
    font-weight: bolder;
    padding-bottom: 200px;

    p {
        margin: 0;
    }

    * {
        color: ${(props: any) => props.theme.colors.darklightblue};
    }
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        margin-top: 20px;
    }
`;

const FlexDiv = styled.div<{ justify?: string; align?: string; grow?: number }>`
    display: flex;
    ${(props: any) =>
        props.justify &&
        css`
            justify-content: ${props.justify} !important;
        `}
    ${(props: any) =>
        props.align &&
        css`
            align-items: ${props.align} !important;
        `}
    ${(props: any) =>
        props.grow !== undefined &&
        css`
            flex-grow: ${props.grow} !important;
        `}
`;

export const Row = styled(FlexDiv)`
    width: 100%;
    padding: 0 13.91vw;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        padding: 0 8vw;
    }
`;

export const Col = styled(FlexDiv)`
    flex-direction: column;
    flex: 1;
`;

export const Title = styled.h2`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 90%;
`;

export const SectionTitle = styled.h3`
    font-weight: bold;
    margin: 0;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        font-weight: 400;
        margin-top: 20px;
    }
`;

export const Description = styled.h5`
    margin-bottom: 30px;
    width: 90%;
`;

export const IndicatorImage = styled.img`
    width: 160px;
`;

export const HorizontalLine = styled.div<{ margin?: number }>`
    content: '';
    width: 100%;
    height: 1px;
    ${(props: any) =>
        props.margin &&
        css`
            margin-top: ${props.margin}px;
            margin-bottom: ${props.margin}px;
        `}
    background-color: rgba(196, 196, 196, 0.5);
`;

export const PlanBlock = styled.div`
    width: 100%;
    margin-top: 30px;
    border-left: 4px solid ${(props: any) => props.theme.colors.yellow};
    padding: 20px 38px;

    h5 {
        margin: 0;
    }
`;

export const PlanGrid = styled.div<{ margin?: { top?: number; bot?: number } }>`
    display: grid;
    width: 350px;
    grid-column-gap: 122px;
    grid-row-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    ${(props: any) =>
        props.margin &&
        css`
            ${props.margin.top && `margin-top: ${props.margin.top}px;`}
            ${props.margin.bot && `margin-bottom: ${props.margin.bot}px;`}
        `}
`;

export const ObsPlanSection = styled.div`
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    width: 100%;
    padding-right: 24px;
    i {
        font-size: 30px;
        margin-right: 20px;
    }

    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        i {
            font-size: 30px;
        }
    }
`;

export const PlanRow = styled.div<{ last?: boolean; noMobile?: boolean }>`
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(196, 196, 196, 0.25);
    padding-bottom: 5px;
    margin-bottom: 5px;
    ${(props: any) =>
        props.last &&
        css`
            margin-bottom: 0;
            border-bottom: none;
        `}
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        border: none !important;
        ${(props: any) =>
            props.last &&
            css`
                margin-top: 20px;
            `}
        ${(props: any) =>
            !props.noMobile &&
            css`
                flex-direction: column;
                margin-bottom: 30px;
            `}
    }
`;

export const PlanCol = styled.div`
    flex: 1;
`;

export const PlanTitle = styled.p<{ noMobile?: boolean }>`
    font-weight: 500;
    margin: 0;
    * {
        margin: 0;
    }
    ${(props: any) =>
        !props.noMobile &&
        css`
            @media (max-width: ${props.theme.breakpoints.mobile}) {
                font-size: 12px;
                color: ${props.theme.colors.gray5};
            }
        `}
`;

export const PlanValue = styled.small<{ detail?: string }>`
    font-weight: 400;
    position: relative;
    ${(props: any) =>
        props.detail &&
        css`
            &::before {
                content: '${props.detail}';
                font-size: 12px;
                font-weight: 300;
                line-height: 12px;
                display: block;
                position: absolute;
                top: -100%;
                left: 0;
            }
        `}
    * {
        margin: 0;
    }
    margin: 0;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        font-size: 14px;
    }
`;

export const PlanTotal = styled.h5`
    font-weight: 500;
    margin: 0;
`;

// Mobile

export const Logo = styled.img`
    width: ${(props: any) => props.theme.sizes.logo};
    margin-bottom: 50px;
`;

export const MobileHeader = styled.div`
    padding: 20px 8vw;

    background-color: ${(props: any) => props.theme.colors.darklightblue};
    * {
        color: #fff;
    }
    padding-bottom: 80px;
    h4 {
        margin-bottom: 40px;
    }
`;

export const InfoIcon = styled.i<{ mobile?: boolean }>`
    color: ${(props: any) => props.theme.colors.gray2} !important;
    display: ${(props: any) => (props.mobile ? 'none' : 'inline')};
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        display: ${(props: any) => (props.mobile ? 'inline' : 'none')};
    }
`;
