export function calculatePlanValue(plan: any, data: any) {
    const newPlan = { ...plan };
    const ageTypes = [
        'age_range_1',
        'age_range_2',
        'age_range_3',
        'age_range_4',
        'age_range_5',
        'age_range_6',
        'age_range_7',
        'age_range_8',
        'age_range_9',
        'age_range_10',
    ];
    let total = 0;
    let totalBene = 0;
    ageTypes.forEach((ageRange: string) => {
        const count = data[ageRange] || 0;
        totalBene += count;
        total += count * parseFloat(plan.ageRanges[ageRange]);
    });
    newPlan.dental *= totalBene;
    newPlan.saude = total;
    newPlan.total = total + newPlan.dental;
    return newPlan;
}

export function getPlans(entries: Array<any>, data?: any) {
    const plans = entries.map((value: any) => {
        const {
            coverage: tableCoverage,
            modality,
            partaking,
            accommodation,
            pk,
            plan: tablePlan,
            age_range_1,
            age_range_2,
            age_range_3,
            age_range_4,
            age_range_5,
            age_range_6,
            age_range_7,
            age_range_8,
            age_range_9,
            age_range_10,
        } = value;
        const {
            name,
            hospital,
            coverage,
            reimbursement,
            dental,
            rating,
            diff,
            observations,
            insurance,
        } = tablePlan;

        const { iof, insurance_company, lack } = insurance;
        const hospitals = hospital.map((value: any) => value.name);
        const plan = {
            id: pk,
            name,
            company: insurance_company,
            accommodation,
            reimbursement,
            partaking,
            coverage,
            tableCoverage,
            hospitals,
            diff,
            dental,
            observations,
            ranking: rating,
            iof,
            modality,
            lack,
            ageRanges: {
                age_range_1,
                age_range_2,
                age_range_3,
                age_range_4,
                age_range_5,
                age_range_6,
                age_range_7,
                age_range_8,
                age_range_9,
                age_range_10,
            },
        };
        try {
            return calculatePlanValue(plan, data);
        } catch (e) {
            return plan;
        }
    });

    return plans;
}
