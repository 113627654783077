/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { HeaderElement, Logo } from './styles';

interface HeaderProps {
    relative?: boolean;
    transparent?: boolean;
}

const Header = (props: HeaderProps) => (
    <HeaderElement
        {...props}
        className="row flex-row justify-content-between align-items-center"
    >
        <a href="https://dwscorretora.com/">
            <Logo
                src={`/img/dws-vector${props.transparent ? '' : '-white'}.svg`}
                desktop
                alt="logo"
            />
            <Logo
                src={`/img/dws-vector${
                    props.transparent ? '' : '-white'
                }-min.svg`}
                alt="logo"
            />
        </a>
    </HeaderElement>
);

export default Header;
