export const defaultTheme = {
    font: {
        family: 'Roboto',
        size: {
            h1: '42px',
            h2: '34px',
            h3: '28px',
            h4: '24px',
            h5: '18px',
            p: '16px',
            a: '55px',
            small: '14px',
            button: '16px',
            input: '10px',
        },
        weight: {
            h1: 700,
            h2: 500,
            h3: 500,
            h4: 400,
            h5: 400,
            p: 400,
            small: 300,
            button: 500,
            input: 300,
        },
        lineHeight: {
            h1: '56px',
            h2: '46px',
            h3: '38px',
            h4: '34px',
            h5: '28px',
            p: '23px',
            small: '20px',
            button: '18px',
            input: '10px',
        },
    },
    spaces: {
        vertical: {
            input: '18px',
            buttons: '30px',
        },
        horizontal: {
            buttons: '10px',
        },
    },
    sizes: {
        logo: '110px',
        minLogo: '35px',
        stepCircle: '20px',
        stepIndicator: '80vw',
        header: '78px',
        desktopInput: '410px',
        mobileInput: '100%',
    },
    colors: {
        darklightblue: '#263246',
        darkblue: '#1E3A57',
        yellow: '#FDCD37',
        mainGray: '#E9E9E9',
        gray: '#212429',
        gray1: '#495057',
        gray2: '#ACB5BD',
        gray3: '#DDE2E5',
        gray4: '#F8F9FA',
        gray5: '#C8C8C8',
        gray6: '#BDB5B5',
        gray7: '#999',
    },
    breakpoints: {
        mobile: '575.98px',
    },
};

export const altTheme = {};
