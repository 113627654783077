import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    max-width: 374px;
    justify-content: space-between;
    @media (max-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        max-width: 100%;
        button {
            margin-bottom: 15px;
            width: 100% !important;
        }
        flex-direction: column;
        div:first-child:not(:last-child) {
            display: none !important;
        }
    }
`;

export const MobileButton = styled.button`
    display: flex;
    @media (min-width: ${(props: any) => props.theme.breakpoints.mobile}) {
        display: none;
    }
    margin: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-top: 10px;
    background-color: transparent;
    border: none !important;
    outline: none !important;
    img {
        width: 25px;
        height: 20px;
    }
    color: ${(props: any) => props.theme.colors.darkblue};
`;

export const RotatingIcon = styled.i`
    position: relative;

    @keyframes Anim {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    animation: Anim 2s linear infinite;
`;
