import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../templates/buttonGroup/styles';


export const Container = styled.div`
    height: 100vh;
    background: #FFFFFF;
    padding-top: 140px;
    margin-left: 13.91vw;
    margin-right: 13.91vw;

    display: flex;
`;


export const Main = styled.div`
    max-width: 1111px;
    display: flex;

    a {
        width: 180px;
        text-decoration: none;
        margin-right: 27px;
        margin-top: 50px;
    }
`;

export const Logo = styled.img`
    margin-left: 100px;
    width: 215px;
    height: 215px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    width: 620px;

    h2 {
        margin-top: 70px;
        font-weight: bold;
        font-size: 30px;
        line-height: 56px;
        letter-spacing: 0.3px;
        color: #263246;
    }

    p {
        font-weight: normal;
        font-size: 20px;
        line-height: 38px;
        letter-spacing: 0.3px;
        color: #212429;
    }
`;

export const Links = styled.div`
    display: flex;

    a {
        text-align: left;
    }
`;

export const ButtonInfo = styled(Button)`
    margin-top: 10px;
    display: block;
    width: 180px;
    margin-right: 27px;
    line-height: 34px;
    font-size: 15px;
`;

export const ContainerMobile = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    margin-bottom: 80px;
    /* background-color: #fff; */
    background-color: ${(props: any) => props.theme.colors.darklightblue};

`;

export const LogoMobile = styled.img`
    width: ${(props: any) => props.theme.sizes.logo};
    margin-bottom: 30px;
`;

export const ModalHeader = styled.header`
    height: 100%;
    padding: 13px 20px;

    /* width: 100%; */

    background-color: ${(props: any) => props.theme.colors.darklightblue};
    * {
        color: #fff;
    }
    i {
        color: ${(props: any) => props.theme.colors.yellow};
    }
    /* padding-bottom: 80px; */
    /* height: 376px; */

    h1 {
        margin-top: 61px;
        line-height: 32px;
        width: 257px;
        height: 60px;
        font-size: 24px;
    }

    p {
        margin-top: 25px;
        width: 282px;
        height: 48px;
    }
`;

export const ButtonMobile = styled(Button)`
    margin-top: 50px;
    margin-right: 10px;

`;
