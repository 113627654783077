import { createGlobalState } from 'react-hooks-global-state';
import { Plan } from '../pages/CompareEContrate/plan';
import setDebugVar from '../utils/debug';

export type Quotation = {
    id?: number;
    company_size?: string;
    age_range_1?: number;
    age_range_2?: number;
    age_range_3?: number;
    age_range_4?: number;
    age_range_5?: number;
    age_range_6?: number;
    age_range_7?: number;
    age_range_8?: number;
    age_range_9?: number;
    age_range_10?: number;
    selected_table?: number;
    existing_plan?: string;
};

export type Client = {
    id?: number;
    name?: string;
    email?: string;
    phone?: string;
    cnpj?: string;
    state?: string;
    role?: string;
};

interface GlobalStateForm {
    quotation: Quotation;
    client: Client;
    tables: Array<any>;
    errors: Map<string, any>;
    selectedPlan?: Plan;
}

const initialState: GlobalStateForm = {
    quotation: {},
    client: {},
    tables: [],
    errors: new Map<string, any>(),
    selectedPlan: undefined,
};

const GlobalState = createGlobalState(initialState);

setDebugVar('global', {
    get: GlobalState.getGlobalState,
    set: GlobalState.setGlobalState,
});

export default GlobalState;
