import { useState, useEffect } from 'react';
import TimeoutManager from '../utils/timeoutManager.js';

interface Deboucing {
    reset(): void;
    stop(): void;
    active: boolean;
}

export default function useDebouncing({
    callback,
    timeout = 0,
}: {
    callback?: (deboucing?: Deboucing) => void;
    timeout?: number;
}): Deboucing {
    const [timeoutFunction, setTimeoutFunction] = useState(TimeoutManager());
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        return () => {
            timeoutFunction.clear();
        };
    }, []);

    const reset = () => {
        setUpdated(false);
        timeoutFunction.clear();
        setTimeoutFunction(
            TimeoutManager(() => {
                setUpdated(true);
            }, timeout),
        );
    };

    const stop = () => {
        setUpdated(false);
        timeoutFunction.clear();
    };

    useEffect(() => {
        if (updated) {
            callback?.({ reset, stop, active: updated });
        }
    }, [updated]);

    return {
        reset,
        stop,
        active: updated,
    };
}

export function useTimeout() {
    const [timeoutFunction, setTimeoutFunction] = useState(TimeoutManager());

    const timeoutWrapper = (callback: () => void, timeout: number) => {
        timeoutFunction.clear();
        return setTimeoutFunction(TimeoutManager(callback, timeout));
    };
    return {
        setTimeout: timeoutWrapper,
    };
}
